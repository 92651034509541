import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../_helpers/auth.guard';
import { AccountManagerComponent } from './account-manager/account-manager.component';
import { AddAdminComponent } from './account-manager/add-admin/add-admin.component';
import { BillingComponent } from './billing/billing.component';
import { CreditBackReportComponent } from './credit-back-report/credit-back-report.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { InstanceComponent } from './instance/instance.component';
import { ManageLabelComponent } from './modals/manage-label/manage-label.component';
import { AddResellerComponent } from './reseller/add-reseller/add-reseller.component';
import { PartnerDetailsComponent } from './reseller/partner-details/partner-details.component';
import { ResellerComponent } from './reseller/reseller.component';
import { SettingsComponent } from './settings/settings.component';
import { SharedComponent } from './shared.component';
import { AddLabelModalComponent } from './modals/add-label-modal/add-label-modal.component';
import { ProductComponent } from './product/product.component';
import { AddProductComponent } from './product/add-product/add-product.component';
import { NewPlanComponent } from './product/add-product/new-plan/new-plan.component';
import { AddAddonComponent } from './product/add-product/add-addon/add-addon.component';
import { VirtualMachineComponent } from './virtual-machine/virtual-machine.component';
import { AddVirtualMachineComponent } from './virtual-machine/add-virtual-machine/add-virtual-machine.component';
import { ServerComponent } from './server/server.component';
import { AddServerComponent } from './modals/add-server/add-server.component';
import { InstanceLogComponent } from './instance-log/instance-log.component';
import { RolesPermissionsComponent } from './roles-permissions/roles-permissions.component';
import { AddRolesPermissionsComponent } from './roles-permissions/add-roles-permissions/add-roles-permissions.component';
import { InstanceUserDetailsComponent } from './instance-user-details/instance-user-details.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { AuditLogComponent } from './audit-log/audit-log.component'
import { TallyReleaseComponent } from "./tally-release/tally-release.component"
import { SubscriptionComponent } from './subscription/subscription.component';
import { DiscountComponent } from './discount/discount.component';
import { DiscountListComponent } from './discount-list/discount-list.component';
import { SubscriptionDetailsComponent } from './subscription/subscription-details/subscription-details.component';
import { UserRoleGuard } from '../_helpers/user-role.guard';
import { UserTypeGuard } from '../_helpers/user-type.guard';
import { MrrReportComponent } from './mrr-report/mrr-report.component';
import { PaymentReportComponent } from './payment-report/payment-report.component';
import { CouponComponent } from './coupon/coupon.component';
import { SchemeDeatilsComponent } from './discount/scheme-deatils/scheme-deatils.component';
import { AccountManagerReportComponent } from './account-manager-report/account-manager-report.component';
import { PartnerReportComponent } from './partner-report/partner-report.component';
import { NewSubscriptionComponent } from './subscription/new-subscription/new-subscription.component';
import { OrderManagementComponent } from './order-management/order-management.component';
import { GoogleDriveComponent } from './google-drive/google-drive.component';
import { BannerComponent } from './banner/banner.component';
import { InactiveSubReportComponent } from './inactive-sub-report/inactive-sub-report.component';
import { TechnicalDashboardComponent } from './technical-dashboard/technical-dashboard.component';
import { ServerDetailsComponent } from './server-details/server-details.component';
const routes: Routes = [
  {
    path: '',
    component: SharedComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: DashboardComponent,
        canActivate: [UserRoleGuard],

      },
      {
        path: 'reseller',
        component: ResellerComponent,
        canActivate: [UserRoleGuard],
      }, {
        path: 'reseller/add-reseller',
        component: AddResellerComponent,
        canActivate: [UserRoleGuard]
      },
      {
        path: 'billing',
        component: BillingComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'setting',
        component: SettingsComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'account-manager',
        component: AccountManagerComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'partner-report',
        component: PartnerReportComponent,
        canActivate: [UserRoleGuard],
      },
      {
        path: 'account-manager-report',
        component: AccountManagerReportComponent,
        canActivate: [UserRoleGuard],
      },
      {
        path: 'account-manager/add-admin',
        component: AddAdminComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'partner-details',
        component: PartnerDetailsComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'product',
        component: ProductComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'product/add-product',
        component: AddProductComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'manage-label',
        component: ManageLabelComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'product/new-plan',
        component: NewPlanComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'product/add-addon',
        component: AddAddonComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'instance',
        component: InstanceComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],

      },
      {
        path: 'cloud-user-details',
        component: UserDetailsComponent
      },
      {
        path: 'cloud-user',
        component: InstanceUserDetailsComponent,

      },
      {
        path: 'instance-log',
        component: InstanceLogComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'add-label',
        component: AddLabelModalComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'virtual-machine',
        component: VirtualMachineComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'add-virtual-machine',
        component: AddVirtualMachineComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'server',
        component: ServerComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'add-server',
        component: AddServerComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'subscription',
        component: SubscriptionComponent,
        canActivate: [UserRoleGuard],
      },
      {
        path: 'roles-permissions',
        component: RolesPermissionsComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'roles-permissions/add-roles-permissions',
        component: AddRolesPermissionsComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'audit-log',
        component: AuditLogComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'tally-release',
        component: TallyReleaseComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'scheme/add',
        component: DiscountComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'coupon-list',
        component: DiscountListComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'coupon',
        component: CouponComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'scheme-details',
        component: SchemeDeatilsComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'subscriptions-details',
        component: SubscriptionDetailsComponent,
        canActivate: [UserRoleGuard],
      },
      {
        path: 'new-subscription',
        component: NewSubscriptionComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'order-management',
        component: OrderManagementComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'mrr-report',
        component: MrrReportComponent,
        canActivate: [UserRoleGuard],
      },
      {
        path: 'payment-report',
        component: PaymentReportComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'google-drive',
        component: GoogleDriveComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'banner',
        component: BannerComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'technical-dashboard',
        component: TechnicalDashboardComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'inactive-sub-report',
        component: InactiveSubReportComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      },
      {
        path: 'server-details',
        component: ServerDetailsComponent,
        canActivate: [UserTypeGuard, UserRoleGuard],
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SharedRoutingModule { }
