<!-- ----------subscription---list----page-- -->
<div class="main-panel">
    <div class="content-wrapper">
        <!-- <form [formGroup]="customerForm"> -->
        <div class="row search-form">
            <div class="card-body">
                <div class="col-md-12">
                    <div class="row mb-2">
                        <div>

                        </div>
                        <div class="d-flex title mb-3 justify-content-between">
                            <div>
                                <h3 class="mb-0 header">{{subDetail?.plan.name | titlecase}} <small
                                        *ngIf="subDetail?.restricted" class="text-muted">(Restricted)</small></h3>
                            </div>
                        </div>
                        <div>
                            <p class="sub-id">#{{subDetail?.code | uppercase}} <span [ngClass]="[(subDetail?.plan?.unitPrice>5)?subDetail?.isDeleted?'expire':
                            subDetail?.isExpired?'expire':'live':'expire']">
                                    {{(subDetail?.plan?.unitPrice>5)?subDetail?.isDeleted?'Deleted':subDetail?.isExpired?'Expired':
                                    'Live':subDetail?.isDeleted?'Deleted':subDetail?.isExpired?
                                    'Trial Expired':'Trial'}}</span>&nbsp;

                                <span class="float-end" *ngIf="!userRole">
                                    <div class="dropdown">
                                        <button class="btn-color  dropdown-toggle" type="button" id="dropdownMenuButton"
                                            data-mdb-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            Action
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                            <span *ngIf="!subDetail?.isDeleted">
                                                <a class="dropdown-item" *ngIf="subDetail?.plan?.unitPrice > 0"
                                                    (click)="changeSubPrice(subDetail?.code,subDetail?.plan?.unitPrice,subDetail?.plan?.name,subDetail?._id)">
                                                    Change Price</a>
                                                <a class="dropdown-item" *ngIf="isShowUpdate"
                                                    (click)="deleteSubscription()">Delete
                                                    Subscriptions</a>
                                                <a class="dropdown-item" *ngIf="subDetail?.plan?.unitPrice > 0"
                                                    (click)="addOn(subDetail)">
                                                    {{subDetail?.addOn?.quantity!=null?'Update': 'Add'}} AddOn</a>
                                                <a class="dropdown-item" *ngIf="subDetail?.addOn?.quantity!=null"
                                                    (click)="RemoveAddon(subDetail)">Remove AddOn
                                                </a>
                                                <a class="dropdown-item"
                                                    (click)="extendToExpiry(subDetail?.code, subDetail?.endDate,subDetail?.plan?.name)">
                                                    Extend Expiry Date</a>
                                                <a class="dropdown-item" (click)="update()" *ngIf="isShowUpdate">Update
                                                    Subscription</a>
                                            </span>
                                            <a class="dropdown-item" *ngIf="subDetail?.isExpired||subDetail?.isDeleted"
                                                (click)="removeTallySerialNo(subDetail?.code)">
                                                Remove Tally Serial No.</a>
                                        </div>
                                    </div>
                                </span>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div>
                            <ul class="d-flex service ps-0 mb-0">
                                <li (click)="toggleSection('overview')"
                                    [ngClass]="(activeSection==='overview') ? 'activate': ''">
                                    <a>Overview</a>
                                </li>
                                <li (click)="toggleSection('invoice-history')"
                                    [ngClass]="(activeSection==='invoice-history') ? 'activate': ''">
                                    <a>Order History</a>
                                </li>
                                <li (click)="toggleSection('recent-activities')"
                                    [ngClass]="(activeSection==='recent-activities') ? 'activate': ''">
                                    <a>Recent Activities</a>
                                </li>
                                <li (click)="toggleSection('licence-details')"
                                    [ngClass]="(activeSection==='licence-details') ? 'activate': ''">
                                    <a>License Details</a>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <!-- OVERVIEW -->

                    <div class="row " *ngIf="activeSection==='overview'">
                        <div class="col-md-5 ">
                            <div class="card-body part-1">
                                <h5 class="company_name">{{subDetail?.customer?.companyName |uppercase}} </h5>
                                <button class=" plan-change mt-2" *ngIf="!userRole"
                                    (click)="customerLogin(subDetail?.code)">Login as a
                                    Customer</button>
                                <div class="d-flex sub-contain mt-4 ">
                                    <span class="icon">
                                        <i class="bi bi-person-fill "></i>
                                    </span>
                                    <div>
                                        <h6 class="sb-txt  mb-0">{{subDetail?.customer?.name |titlecase}}
                                        </h6>
                                        <p class="text-muted pt-0 email">
                                            {{subDetail?.customer?.email |titlecase}}
                                        </p>
                                    </div>
                                </div>
                                <div class="mt-4">
                                    <h5 class="address">{{"address" |uppercase}}</h5>
                                    <hr />
                                    <p>Billing Address</p>
                                    <p>{{subDetail?.customer?.billing?.address |
                                        titlecase}}&nbsp;{{subDetail?.customer?.billing?.city |
                                        titlecase}}&nbsp;
                                        {{selectedState?.name | titlecase}}
                                        &nbsp;{{subDetail?.customer?.billing?.country | titlecase}}
                                        &nbsp;
                                        {{subDetail?.customer?.billing?.pinCode}} </p>
                                </div>

                                <div class="mt-4">
                                    <h5 class="subscription">SUBSCRIPTION OPTIONS</h5>
                                    <hr />
                                    <div class="row">
                                        <div class="col-sm-12 text-muted">
                                            <p>Subscription ID <span class="float-end">{{subDetail?.code |
                                                    uppercase}}</span></p>
                                            <p>Subscriber Since<span class="float-end">{{subDetail?.startDate |
                                                    date:"dd/MM/yyyy"}}</span></p>
                                            <p>Sales Person <span class="float-end">{{subDetail?.salesPerson?.name |
                                                    titlecase}}</span></p>
                                            <p>Place of Supply <span class="float-end"
                                                    *ngIf="selectedState">{{selectedState?.name |
                                                    titlecase}}({{selectedState?.key}})</span></p>
                                            <p>GST Treatment <span
                                                    class="float-end">{{subDetail?.customer?.billing?.gstTreatment
                                                    }}</span></p>
                                            <p>Total Lifetime logins <span
                                                    class="float-end">{{subDetail?.lifetimeLogin}}</span></p>
                                            <p>Logins(past 30 days) <span
                                                    class="float-end">{{subDetail?.loginInLast30Days}}</span></p>
                                            <p>Last Login <span class="float-end">{{subDetail?.lastLogin |
                                                    date:"dd/MM/yyyy,hh:mm a": "UTC + 05:30"}}</span></p>
                                            <p>Tally Serial No.<span
                                                    class="float-end">{{subDetail?.tallySerialNo}}</span></p>
                                            <p>Tally Serial No By Customer<span
                                                    class="float-end">{{subDetail?.tallySerialNoByCustomer}}</span></p>
                                            <p>ShowFileManager<span
                                                    class="float-end">{{subDetail?.customer?.isFileManagerShow?'Yes':'No'}}&nbsp;
                                                    <a class="link" *ngIf="!subDetail?.isDeleted && !userRole"
                                                        (click)="updateCustomerData(!subDetail?.customer?.isFileManagerShow)">
                                                        Update</a></span>
                                            </p>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-7 ">
                            <div class="table-responsive">
                                <table class="table table-borderless table-sm roundedTable">
                                    <!-- <ng-template #tipContent>
                                        <p class="text-start mb-0">Billing Date : {{subDetail?.nextEndDate}}</p>
                                        <p class="text-start mb-0">No Of Days : {{subDetail?.noOfDays}}</p>
                                        <p class="text-start mb-0">Amount : {{subDetail?.deductedAmount}}</p>
                                    </ng-template> -->
                                    <thead class="text-muted">
                                        <tr>
                                            <th scope="col">Subscription Amount</th>

                                            <th scope="col">Next Billing Date
                                                <!-- <i class="bi bi-info-circle ms-2"
                                                    *ngIf="!(subDetail?.plan?.unitPrice == 0 || subDetail?.isDeleted)"
                                                    placement="left" [ngbTooltip]='tipContent'></i> -->
                                            </th>
                                            <th scope="col">Last Billing Date</th>
                                            <th scope="col "><span
                                                    [ngClass]="subDetail?.isAutoRenew?'text-success':'text-danger'">
                                                    {{subDetail?.isAutoRenew?'Renew forever':'Expiry on'}}<i
                                                        class="bi bi-info-circle ms-2" placement="left"
                                                        ngbTooltip="{{subDetail?.isAutoRenew ?'The subscription will automatically renew on the next billing date and the amount will be deducted from your wallet.':'The subscription will automatically expire and the associated data will be permanently deleted in 15 days after expiry date.'}}"></i></span>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody class="text-center">
                                        <tr>
                                            <td class="pt-0 ">&#8377; {{subDetail?.plan?.unitPrice *
                                                subDetail?.plan?.noOfUsers}}
                                                <p class="text-muted text-center">
                                                    {{subDetail?.plan?.billEvery}}&nbsp;{{subDetail?.plan?.billingCycle}}(s)
                                                </p>
                                            </td>
                                            <td class="pt-0"> {{subDetail?.endDate | date:"d MMM y"}} <p class="m-t-4">
                                                </p>
                                            </td>
                                            <td class="pt-0">
                                                {{(subDetail?.lastRenewDate===null?subDetail?.startDate:subDetail?.lastRenewDate)
                                                | date:"d MMM y"}}<p class="m-t-4"></p>
                                            </td>
                                            <td class="pt-0  ">
                                                <i class="bi bi-infinity fs-3" *ngIf="subDetail?.isAutoRenew"></i>
                                                <span
                                                    *ngIf="!subDetail?.isAutoRenew">{{(subDetail?.endDate===null?subDetail?.startDate:subDetail?.endDate)
                                                    | date:"d MMM y"}}</span>
                                                <p class="m-t-4"></p>
                                            </td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                            <!-- <hr/> -->
                            <div class="table-responsive">
                                <table class="table table-sm ">
                                    <thead class="text-muted ">
                                        <tr>
                                            <th scope="col">Plan & Addon Details</th>
                                            <th scope="col">Qty/No Of Users
                                            </th>
                                            <th scope="col">Rate</th>
                                            <th scope="col">Tax</th>
                                            <th scope="col">Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody class="font-weight-bold1">
                                        <tr>
                                            <td>{{subDetail?.plan?.name |titlecase}}</td>
                                            <td>{{subDetail?.plan?.noOfUsers}}

                                            </td>
                                            <td>&#8377;{{subDetail?.plan?.unitPrice | number :
                                                "1.0-2"}}</td>
                                            <td>&#8377;{{((subDetail?.plan?.unitPrice *subDetail?.plan?.noOfUsers)*.18)
                                                |number:"1.0-2"}}</td>
                                            <td>&#8377;{{(subDetail?.plan?.unitPrice * subDetail?.plan?.noOfUsers)|
                                                number :
                                                "1.0-2"}}</td>
                                        </tr>
                                        <tr *ngIf="!(subDetail?.addOn?.description===null)">
                                            <td>
                                                <div class="d-inline-block text-truncate" style="max-width: 200px;"
                                                    ngbTooltip="{{subDetail?.addOn?.description |titlecase}}"
                                                    placement="top">{{subDetail?.addOn?.description |titlecase}}</div>
                                            </td>
                                            <td>{{subDetail?.addOn?.quantity}}</td>
                                            <td>&#8377;{{subDetail?.addOn?.price | number :
                                                "1.0-2"}}</td>
                                            <td>&#8377;{{((subDetail?.addOn?.price * subDetail?.addOn?.quantity)*.18)
                                                |number:"1.0-2"}}</td>
                                            <td>&#8377;{{(subDetail?.addOn?.price * subDetail?.addOn?.quantity)| number
                                                :
                                                "1.0-2"}}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>Sub Total</td>
                                            <td>&#8377;{{((subDetail?.plan?.unitPrice *
                                                subDetail?.plan?.noOfUsers)+(subDetail?.addOn?.price *
                                                subDetail?.addOn?.quantity))| number :
                                                "1.0-2"}}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td>IGST18(18%)</td>
                                            <td>&#8377;{{(((subDetail?.plan?.unitPrice *
                                                subDetail?.plan?.noOfUsers)+(subDetail?.addOn?.price *
                                                subDetail?.addOn?.quantity))*.18)| number :
                                                "1.0-2"}}</td>
                                        </tr>
                                        <tr>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td class="text-uppercase">Total</td>
                                            <td>&#8377;{{(((subDetail?.plan?.unitPrice *
                                                subDetail?.plan?.noOfUsers)+(subDetail?.addOn?.price *
                                                subDetail?.addOn?.quantity))*1.18)| number :
                                                "1.0-2"}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>

                    <!-- INVOICE HISTORY -->
                    <div class="row mb-4" *ngIf="activeSection==='invoice-history'">
                        <div class="col-sm-12 table-responsive">
                            <h4 class="mt-2">Billing Information</h4>
                            <table class="table table-sm">
                                <thead class="text-muted">
                                    <tr>
                                        <th scope="col">Bill No.</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Action</th>
                                        <th scope="col">Credit</th>
                                        <th scope="col">Debit</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let billingData of subBillInfo">
                                        <td>{{billingData?.billNumber}}</td>
                                        <td>{{billingData?.createdAt |date:'dd/MM/YYYY'}}</td>
                                        <td>{{billingData?.action }}</td>
                                        <td class="text-success">&#8377; {{billingData?.credit | number :"1.0-2"}}</td>
                                        <td><span class="text-danger">&#8377; {{billingData?.debit | number
                                                :"1.0-2"}}</span></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col-sm-12 table-responsive"
                            *ngIf="(subDetail?.partnerId==='645a4eca8c6428e9ac655957') && subPaymentData.length>0">
                            <h4 class="mt-2">Payment Information</h4>
                            <table class="table table-sm">
                                <thead class="text-muted">
                                    <tr>
                                        <th scope="col">OrderId</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">Amount</th>
                                        <th scope="col">Staus</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let payment of subPaymentData">
                                        <td>{{payment?.orderId}}</td>
                                        <td>{{payment?.createdAt |date:'dd/MM/YYYY'}}</td>
                                        <td>&#8377; {{payment?.amount | number :"1.0-2"}}</td>
                                        <td *ngIf="payment?.orderStatus === 'Successful' || payment?.orderStatus === 'Shipped'"
                                            class="text-success">
                                            Success
                                        </td>
                                        <td *ngIf="payment?.orderStatus === 'Unsuccessful' || payment?.orderStatus === 'Invalid' || payment?.orderStatus === 'Cancelled' || payment?.orderStatus === 'Timeout' || payment?.orderStatus === 'Aborted'"
                                            class="text-danger">
                                            Failed
                                        </td>
                                        <td *ngIf="payment?.orderStatus === 'Initiated' || payment?.orderStatus === 'Awaited'"
                                            class="text-warning">
                                            Pending
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <!-- RECENT ACTIVITIES -->
                    <div class="row mt-4 mb-4" *ngIf="activeSection==='recent-activities'">
                        <div class="col-sm-12">
                            <ul class="events">
                                <li *ngFor="let activity of subActivityLog">
                                    <time class="col-2">{{activity?.createdAt | date:"dd MMM y"}}<br />
                                        {{activity?.createdAt | date:'h:mm a': "UTC + 05:30"}}</time>
                                    <span class="d-inline-block col-10 ps-5"><strong
                                            class="activities">{{activity?.description}} </strong> By
                                        {{activity?.taskBy?.name}}<small *ngIf="activity?.remark">, Remark:
                                            {{activity?.remark}}</small></span>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="row mb-4" *ngIf="activeSection==='licence-details'">
                        <div class="col-sm-12 table-responsive">
                            <h4 class="mt-2">Licence Details</h4>
                            <table class="table table-sm">
                                <thead class="text-muted">
                                    <tr>
                                        <th scope="col">Product</th>
                                        <th scope="col">Tally S.no</th>
                                        <th scope="col">Release</th>
                                        <th scope="col">TSS Expiry </th>
                                        <th scope="col">License Email </th>
                                        <th scope="col">Last Updated</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let deatils of  subDetail?.tallyDatas">
                                        <td>{{deatils?.product}}</td>
                                        <td>{{deatils?.serialNumber }}</td>
                                        <td>{{deatils?.tallyVersion}}</td>
                                        <td>{{deatils?.tallySoftwareServices}}</td>
                                        <td>{{deatils?.licenseAdministratorEmail}}</td>
                                        <td>{{deatils?.lastUpdate }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>


                    </div>

                    <!---- LICENSE DETAILS ----->

                </div>
            </div>
        </div>
    </div>