<div class="modal-header border-0 py-0">
    <h4 class="modal-title pull-left border-0 p-0"><b>{{isEdit? 'Update':
            'Add New'}} Server</b></h4>
</div>
<form action="" [formGroup]="serverForm">
    <div class="modal-body pt-0 pb-0">
        <div class="row mb-3">
            <div class="col-sm-3">
                <label for="fullName" class="col-form-label">Full Name</label>
                <div class=" mt-1">
                    <input type="text" class="form-control" id="fullName" placeholder="Enter Name"
                        formControlName="name">
                    <div *ngIf="serverForm.get('name')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                            *ngIf="serverForm.get('name')?.errors?.['required'] && (serverForm.get('name')?.dirty || serverForm.get('name')?.touched)">
                            <p class="text-danger m-0 p-0"><small>Name is mandatory.</small>
                            </p>
                        </div>
                        <div class="error-message" *ngIf="serverForm.get('name')?.errors?.['pattern']">
                            <p class="text-danger m-0 p-0"><small>Please enter valid Name.</small></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <label for="fullName" class="col-form-label">IP Address</label>
                <div class=" mt-1">
                    <input type="text" class="form-control" id="fullName" placeholder="Enter IP Address"
                        formControlName="ip">
                    <div *ngIf="serverForm.get('ip')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                            *ngIf="serverForm.get('ip')?.errors?.['required'] && (serverForm.get('ip')?.dirty || serverForm.get('ip')?.touched)">
                            <p class="text-danger m-0 p-0"><small>IP Address is mandatory.</small>
                            </p>
                        </div>
                        <div class="error-message" *ngIf="serverForm.get('ip')?.errors?.['pattern']">
                            <p class="text-danger m-0 p-0"><small>Please enter valid IP Address.</small></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <label for="fullName" class="col-form-label">Public IP Address</label>
                <div class=" mt-1">
                    <input type="text" class="form-control" id="fullName" placeholder="Enter Public IP Address"
                        formControlName="publicIp">
                    <div *ngIf="serverForm.get('publicIp')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                            *ngIf="serverForm.get('publicIp')?.errors?.['required'] && (serverForm.get('publicIp')?.dirty || serverForm.get('publicIp')?.touched)">
                            <p class="text-danger m-0 p-0"><small>Public IP is mandatory.</small>
                            </p>
                        </div>
                        <div class="error-message" *ngIf="serverForm.get('publicIp')?.errors?.['pattern']">
                            <p class="text-danger m-0 p-0"><small>Please enter valid IP Address.</small></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <label for="fullName" class="col-form-label">Domain Name</label>
                <div class="mt-1">
                    <input type="text" class="form-control" id="fullName" placeholder="Enter Domain Name"
                        formControlName="domainName">
                    <div *ngIf="serverForm.get('domainName')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                            *ngIf="serverForm.get('domainName')?.errors?.['required'] && (serverForm.get('domainName')?.dirty || serverForm.get('domainName')?.touched)">
                            <p class="text-danger m-0 p-0"><small>Domain Name is mandatory.</small>
                            </p>
                        </div>
                        <div class="error-message" *ngIf="serverForm.get('domainName')?.errors?.['pattern']">
                            <p class="text-danger m-0 p-0"><small>Please enter valid Domain Name.</small></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-3">

            <div class="col-sm-3">
                <label for="fullName" class=" col-form-label">RAM</label>
                <div class="mt-1">
                    <input type="number" class="form-control" id="fullName" placeholder="Enter ram" formControlName="ram">
                    <div *ngIf="serverForm.get('ram')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                            *ngIf="serverForm.get('ram')?.errors?.['required'] && (serverForm.get('ram')?.dirty || serverForm.get('ram')?.touched)">
                            <p class="text-danger m-0 p-0"><small>RAM is mandatory.</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <label for="fullName" class=" col-form-label">CPU</label>
                <div class="mt-1">
                    <input type="number" class="form-control" id="fullName" placeholder="Enter cpu" formControlName="cpu">
                    <div *ngIf="serverForm.get('cpu')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                            *ngIf="serverForm.get('cpu')?.errors?.['required'] && (serverForm.get('cpu')?.dirty || serverForm.get('cpu')?.touched)">
                            <p class="text-danger m-0 p-0"><small>CPU is mandatory.</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <label for="fullName" class=" col-form-label">Storage</label>
                <div class="mt-1">
                    <input type="number" class="form-control" id="fullName" placeholder="Enter storage"
                        formControlName="storage">
                    <div *ngIf="serverForm.get('storage')?.invalid" class="alert mb-0 p-0">
                        <div class="error-message"
                            *ngIf="serverForm.get('storage')?.errors?.['required'] && (serverForm.get('storage')?.dirty || serverForm.get('storage')?.touched)">
                            <p class="text-danger m-0 p-0"><small>Storage is mandatory.</small>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <label for="fullName" class="col-form-label">Description</label>
                <div class="mt-1">
                    <textarea rows="1" class="form-control " id="fullName" placeholder="Description"
                        style="overflow:hidden" formControlName="description"></textarea>
                </div>
            </div>
        </div>

        <div class="row mb-3">
            <div class="col-sm-3">
                <label for="fullName" class=" col-form-label">Select exe for Api call</label>
                <div class=" mt-1">
                    <select class="form-select text-muted" formControlName="selectExe">
                        <option value="oldExe">Old exe</option>
                        <option value="newExe">New exe</option>
                    </select>
                </div>
            </div>
            <div class="col-sm-8">
                <div class="row">
                    <label for="fullName" class="col-form-label">Server Type</label>
                    <div class="col-sm-3 mt-3 mb-2 ">
                        <label class="form-check-label  me-2" for="liveCloudRadio">Live</label>
                        <input class="form-check-input" type="radio" id="liveCloudRadio" formControlName="serverType"
                            value="live">
                    </div>
                    <div class="col-sm-3 mt-3 mb-2 ">
                        <label class="form-check-label me-2" for="trialRadio">Trial</label>
                        <input class="form-check-input" type="radio" id="trialRadio" formControlName="serverType"
                            value="trial">
                    </div>
                    <div class="col-sm-3 mt-3 mb-2 ">
                        <label class="form-check-label me-2" for="margCloudRadio">Marg Cloud</label>
                        <input class="form-check-input" type="radio" id="margCloudRadio" formControlName="serverType"
                            value="marg">
                    </div>
                    <div class="col-sm-3 mt-3 mb-2 ">
                        <label class="form-check-label me-2" for="partnerCloudRadio">Partner Cloud</label>
                        <input class="form-check-input" type="radio" id="partnerCloudRadio" formControlName="serverType"
                            value="partnerCloud">
                    </div>
                </div>

            </div>

        </div>

    </div>

    <div class="modal-footer border-0 p-0 m-0">
        <div class="row">
            <div class="col-sm-12 mt-2 text-end">
                <button type="submit" class="comman-btn1 btn-primary me-2" (click)="submit()"
                    [disabled]="!this.serverForm.valid">{{isEdit? 'Update':
                    'Submit'}}</button>
                <button type="button" class="comman-btn1 btn-secondary text-light me-2"
                    (click)="closedModal()">Cancel</button>
            </div>
        </div>
    </div>
</form>