import { Component, OnInit } from '@angular/core';
import { stateList } from '../../../../../state';
import { SubscriptionService } from 'src/app/services/subscription.service';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ChangeSubPriceComponent } from '../../modals/change-sub-price/change-sub-price.component';
import { AddOnComponent } from '../../modals/add-on/add-on.component';
import { ExtendToExpiryComponent } from '../../modals/extend-to-expiry/extend-to-expiry.component';
import { DeleteModalComponent } from '../../delete-modal/delete-modal.component';
import * as moment from 'moment';
@Component({
  selector: 'app-subscription-details',
  templateUrl: './subscription-details.component.html',
  styleUrls: ['./subscription-details.component.scss']
})
export class SubscriptionDetailsComponent implements OnInit {

  subDetail: any
  activeSection: string = 'overview';
  customer: any
  stateList: any = stateList
  subBillInfo: any
  subPaymentData: any
  selectedState: any
  subId: string = ''
  subActivityLog: any
  bsModalRef!: BsModalRef;
  isShowUpdate: boolean = false
  constructor(
    private subscriptionService: SubscriptionService,
    private toastr: ToastrService,
    private modalService: BsModalService,
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.subId = this.activeRoute.snapshot.queryParamMap.get('subId') || '';
    this.subscriptionActivityLog()
    this.subscriptionDetails()
    this.subscriptionBillingInfo()
  }
  subscriptionDetails() {
    this.subscriptionService.subscriptionDetails(this.subId).subscribe((res: any) => {
      if (res.success) {
        this.subDetail = res.subscription
        this.isShowUpdate = this.subDetail?.plan.code?.toLowerCase().includes("vm");
        this.selectedState = stateList.find(state => state.key === res.subscription?.customer?.billing?.state);
      }
    },
      (error) => {
        this.toastr.warning(error.error.message);
      }
    );
  }

  customerLogin(subId: string) {
    const payload = {
      subId: subId,
    }
    this.subscriptionService.loginAsCustomer(payload).subscribe((res: any) => {
      if (res.success) {
        const url = `http://${res.user.domainName}/loginWithPartner?token=${res.user.token} `
        window.open(url, '_blank');
      } else {
        this.toastr.warning(res.message)
      }
    },
      (error) => {
        this.toastr.error(error.error.message)
      });
  }

  subscriptionBillingInfo() {
    this.subscriptionService.subBillingInfo(this.subId).subscribe((res: any) => {
      if (res.success) {
        this.subBillInfo = res.billingData;
        this.subPaymentData = res.paymentData
      }
    })
  }
  subscriptionActivityLog() {
    this.subscriptionService.subRecentActivityLog(this.subId).subscribe((res: any) => {
      if (res.success) {
        this.subActivityLog = res.subscriptionLogs
      }
    })
  }

  changeSubPrice(code: string, unitPrice: number, planName: string, subscriptionId: string) {
    if (planName.includes('Trial')) {
      this.toastr.warning('This is Trial plan price can not be changed in this plan.')
    } else {
      this.bsModalRef = this.modalService.show(ChangeSubPriceComponent, {
        class: 'modal-md',
        backdrop: 'static',
      });
      this.bsModalRef.content.subCode = code;
      this.bsModalRef.content.currentPrice = unitPrice;
      this.bsModalRef.content.planName = planName;
      this.bsModalRef.content.subscriptionId = subscriptionId;

      this.bsModalRef.content.event.subscribe((res: any) => {
        if (res === 'ok') {
          this.subscriptionDetails()
        }
      });
    }

  }

  addOn(subData: any) {
    this.bsModalRef = this.modalService.show(AddOnComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.subData = subData;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.subscriptionDetails()
      }
    });
  }

  RemoveAddon(subData: any) {
    const currentDate = moment()
    const subscriptionEndDate = moment(subData.endDate)
    const subscriptionRemainingDays = subscriptionEndDate.diff(currentDate, 'days'); //how many days of subscription on current date
    const subscriptionDays = (subData.plan.billingCycle === "month") ? 30 : 365
    const subscriptionTotalDays = subscriptionDays * subData.plan.billEvery
    const addonOneDayPrice = subData.addOn.price / subscriptionTotalDays
    const returnAmount = Math.round(addonOneDayPrice * subData.addOn.quantity * subscriptionRemainingDays)
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.returnAmount = returnAmount;
    this.bsModalRef.content.removeAddonsubId = subData.code;
    this.bsModalRef.content.title = 'Remove Addon';
    this.bsModalRef.content.message = 'Are you sure you want to remove this addOn?';
    this.bsModalRef.content.confirmText = 'Yes';
    this.bsModalRef.content.cancelText = 'Cancel';
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.subscriptionDetails()
      }
    });
  }

  removeTallySerialNo(subId: string) {
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.tallyId = 'abc';
    this.bsModalRef.content.title = 'Remove Tally Serial No.';
    this.bsModalRef.content.message = 'Are you sure you want to remove serial number from this subscription.';
    this.bsModalRef.content.confirmText = 'Yes';
    this.bsModalRef.content.cancelText = 'Cancel';
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.subscriptionService.removeTallySerialNumber(subId).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success(res.message)
            this.subscriptionDetails()
          }
        }, (err) => {
          this.toastr.error(err.error.message)
        })
      }
    });
  }

  extendToExpiry(code: string, endDate: string, planName: string) {
    this.bsModalRef = this.modalService.show(ExtendToExpiryComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.subCode = code;
    this.bsModalRef.content.endDate = endDate;
    this.bsModalRef.content.planName = planName;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.subscriptionDetails()
      }
    });
  }

  deleteSubscription() {
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-md',
      backdrop: 'static',
    });

    this.bsModalRef.content.subscriptionId = this.subDetail.code;
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.subscriptionDetails();
      }
    });
  }

  updateCustomerData(isFileManagerShow: boolean) {
    this.bsModalRef = this.modalService.show(DeleteModalComponent, {
      class: 'modal-md',
      backdrop: 'static'
    })
    this.bsModalRef.content.tallyId = 'abc';
    this.bsModalRef.content.title = 'Hide And Show File Manger';
    this.bsModalRef.content.message = `Are you certain you want to ${isFileManagerShow ? 'show' : 'hide'} the file manager ${isFileManagerShow ? 'to' : 'from'} the user?`;
    this.bsModalRef.content.confirmText = 'Yes';
    this.bsModalRef.content.cancelText = 'Cancel';
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        const payload = {
          customerId: this.subDetail.customerId,
          isFileManagerShow: isFileManagerShow
        }
        this.subscriptionService.updateCustomerData(payload).subscribe((res: any) => {
          if (res.success) {
            this.toastr.success(res.message)
            this.subscriptionDetails()
          }
        }, (err) => {
          this.toastr.error(err.error.message)
        })
      }
    });

  }

  toggleSection(section: string) {
    this.activeSection = section
  }

  get userRole() {
    return this.authService.userRole()
  }

  update() {
    this.router.navigate(['new-subscription'], { queryParams: { subId: this.subId } })
  }

  get userType() {
    return this.authService.userType()
  }

}
