import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class TechnicalDashboardService {
  constructor(private http: HttpClient) { }

  getInstanceReport(): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/admin/technicalDashboard/stats`)
  }

  serverDetailsList(page: number, limit: number, searchPayload: string): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/admin/technicalDashboard/report?page=${page}&limit=${limit}&search=${searchPayload}`)
  }

  serverSummaryReport(searchPayload: string): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/admin/technicalDashboard/summary?search=${searchPayload}`)
  }

  getServerDetail(cloudId: any): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/admin/technicalDashboard/serverDetails?cloudId=${cloudId}`)
  }

  getLoginTrendGraphData(cloudId: any, type: any): Observable<any> {
    return this.http.get<any>(`${environment.apiURL}/admin/technicalDashboard/loginTrendGraph?cloudId=${cloudId}&type=${type}`)
  }

}


