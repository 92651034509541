import { Component, OnInit, ViewChild } from '@angular/core';
import { DashboardService } from 'src/app/services/dashboard.service';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { FormControl, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { AdminService } from 'src/app/services/admin.service';
import { PartnerService } from 'src/app/services/partner.service';
import * as moment from 'moment';
import {Router } from '@angular/router';
import * as Highcharts from "highcharts";
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [DatePipe]
})
export class DashboardComponent implements OnInit {
  @ViewChild('timeLineSelect') timeLineSelect!: MatSelect;
  @ViewChild('multiSelect1') multiSelect1!: MatSelect;
  @ViewChild('multiSelect2') multiSelect2!: MatSelect;
  private unsubscriber: Subject<void> = new Subject<void>();
  Highcharts: typeof Highcharts = Highcharts; // Highcharts, it's Highcharts
  paidSubTrendOption: Highcharts.Options = {}
  riskyCustomerChartOption: Highcharts.Options = {}
  trialReportChartOption: Highcharts.Options = {}
  updateFromInput: boolean = true
  objFromInput: any = {}
  activeSection: string = 'day';
  activeSectionForPaid: string = 'day';
  filterPartnerList: any
  selectedMonth: string = '';
  selectedYear: any;
  allSelected = false;
  allPartnerSelected = false
  noDataFound: boolean = false;
  stage: any = [];
  customer: any = [];
  partner: any;
  wallet: any;
  partnerList: any;
  newPaid: any
  totalPaid: any
  upcomingRenewal: any
  accountManagerList: any
  currentMonth: any;
  currentYear: any;
  riskyCustomer: any;
  tiralData: any;
  trialEpired: any;
  trialPaid: any;
  totalTrialSubGraph: any
  expiredSubGraph: any
  trialToPaidSubGraph: any
  isShowCategory: boolean = false
  isShowDowngradeCategory: boolean = false
 
  userId: string = ''
  currentUserRole: string = ""
  liveTrial: any
  chartType: string = 'column'
  paidChartType: string = 'column'
  isShow:any
  AllWeekMonthYear = [
    { value: 'this_week', label: 'This Week' },
    { value: 'last_week', label: 'Last Week' },
    { value: 'next_week', label: 'Next Week' },
    { value: 'this_month', label: 'This Month' },
    { value: 'last_month', label: 'Last Month' },
    { value: 'next_month', label: 'Next Month' },
    { value: 'this_year', label: 'This Year' },
    { value: 'last_year', label: 'Last Year' },
    { value: 'next_year', label: 'Next Year' },
  ]
  subscriptionSummary: any[] = [
    { label: 'Total Renewals', data: [], iconClass: 'bi-graph-up-arrow text-success ms-2' },
    { label: 'Renewed', data: [], iconClass: 'bi-graph-up-arrow text-success ms-2' },
    {
      label: 'Upgrades',
      hasSubCategories: true,
      isExpanded: false,
      data: [],
      iconClass: 'bi-graph-up-arrow text-success ms-2',
      subCategories: [
        { id: '3.1', label: 'User', data: [], iconClass: 'bi-graph-up-arrow text-success ms-2' },
        { id: '3.2', label: 'Price', data: [], iconClass: 'bi-graph-up-arrow text-success ms-2' },
        { id: '3.3', label: 'Plan', data: [], iconClass: 'bi-graph-up-arrow text-success ms-2' }
      ]
    },
    {
      label: 'Downgrade',
      hasSubCategories: true,
      isExpanded: false,
      data: [],
      iconClass: 'bi-graph-down-arrow text-danger ms-2',
      subCategories: [
        { id: '4.1', label: 'User', data: [], iconClass: 'bi-graph-down-arrow text-danger ms-2' },
        { id: '4.2', label: 'Price', data: [], iconClass: 'bi-graph-down-arrow text-danger ms-2' },
        { id: '4.3', label: 'Plan', data: [], iconClass: 'bi-graph-down-arrow text-danger ms-2' }
      ]
    },
    { label: 'Upcoming Renewals', data: [], iconClass: 'bi-graph-up-arrow text-success ms-2' },
    { label: 'Expired', data: [], iconClass: 'bi-graph-down-arrow text-danger ms-2' },
    { label: 'Deleted', data: [], iconClass: 'bi-graph-down-arrow text-danger ms-2' }
  ];
  constructor(
    public authService: AuthService,
    private dashboardService: DashboardService,
    private adminService: AdminService,
    private partnerService: PartnerService,
    private datePipe: DatePipe,
    private router: Router,
  ) {
    this.currentYear = new Date().getFullYear();
    this.currentMonth = this.datePipe.transform(new Date(), 'MMMM');
    this.selectedMonth = this.currentMonth;
    this.selectedYear = this.currentYear;
  }

  searchForm = new FormGroup(
    {
      accountManagerIds: new FormControl([]),
      partnerIds: new FormControl([]),
      timeLine: new FormControl('this_month'),
      searchText: new FormControl(''),
      searchText1: new FormControl(''),
      startDate: new FormControl(''),
      endDate: new FormControl(''),
    }
  )

  ngOnInit(): void {
    this.allPartnerList()
    this.userId = localStorage.getItem('userId') || ''
    this.currentUserRole = localStorage.getItem('role') || ''
    const startDate = moment().startOf('month').toISOString();
    const endDate = moment().endOf('month').toISOString();
    this.searchForm.controls.startDate.setValue(startDate);
    this.searchForm.controls.endDate.setValue(endDate)
    history.pushState(null, '');
    this.searchForm.controls.timeLine.valueChanges.subscribe((value: any) => {
      this.dateRange(value)
    })
    fromEvent(window, 'popstate').pipe(
      takeUntil(this.unsubscriber)
    ).subscribe((_) => {
      history.pushState(null, '');
    });
    this.accountManagerLists()
    this.submitAll()
  }

  submitAll() {
    this.subscriptionRenewalReport();
    this.subscriptionReport();
    this.riskyCustomerReport();
    this.tiralReportData()
    this.liveSubscriptionSummery()
  }
 
  sendDataToSubscription(subId: string) {
    if (subId.length > 0) {
      this.dashboardService.setData(subId);
      this.router.navigate(['/subscription']);
    }
  }

  clickableData(subType: string){
    if (subType == 'paid') {
      this.router.navigate(['subscription'], { queryParams: { subType: subType } })
    }
  }

  allPartnerList() {
    this.partnerService.allPartnerList().subscribe((res: any) => {
      if (res.success) {
        this.partnerList = res.partners
        this.filterPartnerList = res.partners
      }
    })
  }

  accountManagerLists() {
    this.adminService.accountManagerList().subscribe((res: any) => {
      if (res.success) {
        this.accountManagerList = res?.accountManagers
      }
    })
  }

  subscriptionRenewalReport() {
    const searchPayload = {
      partnerIds: this.searchForm.controls.partnerIds.value,
      accountManagerIds: this.searchForm.controls.accountManagerIds.value,
      startDate: this.searchForm.controls.startDate.value,
      endDate: this.searchForm.controls.endDate.value
    }
    this.dashboardService.subscriptionRenew(JSON.stringify(searchPayload)).subscribe((res: any) => {
      if (res.success) {
        this.updateSubscriptionSummary(res);
      }
    })
  }
  updateSubscriptionSummary(res: any) {
    const mapping: any = {
      'Total Renewals': res?.totalRenewals,
      'Renewed': res?.renewed,
      'Upgrades': res?.upgrade,
      'Downgrade': res?.downgrade,
      'Upcoming Renewals': res?.upcomingRenewals,
      'Expired': res?.expired,
      'Deleted': res?.deleted
    };

    const upgradeMapping: any = {
      'User': res?.userUpgradeCategories,
      'Price': res?.priceUpgradeCategories,
      'Plan': res?.planUpgradeCategories
    };

    const downgradeMapping: any = {
      'User': res?.userDowngradeCategories,
      'Price': res?.priceDowngradeCategories,
      'Plan': res?.planDowngradeCategories
    };

    this.subscriptionSummary.forEach((category:any) => {
      category.data = mapping[category.label] || [];
      if (category.hasSubCategories && category.subCategories) {
        category.subCategories.forEach((subCategory: any) => {
          if (category.label === 'Upgrades') {
            subCategory.data = upgradeMapping[subCategory.label] ?? [];
          } else if (category.label === 'Downgrade') {
            subCategory.data = downgradeMapping[subCategory.label] ?? [];
          }
        });
      }
    });
  }

  subscriptionReport() {
    const searchPayload = {
      partnerIds: this.searchForm.controls.partnerIds.value,
      accountManagerIds: this.searchForm.controls.accountManagerIds.value,
      startDate: this.searchForm.controls.startDate.value,
      endDate: this.searchForm.controls.endDate.value
    }
    this.dashboardService.subscriptionReport(JSON.stringify(searchPayload)).subscribe((res: any) => {
      if (res.success) {
        this.newPaid = res?.newPaidSub
        this.totalPaid = res?.totalPaidSub
      }
    })
  }

  riskyCustomerReport() {
    const searchPayload = {
      partnerIds: this.searchForm.controls.partnerIds.value,
      accountManagerIds: this.searchForm.controls.accountManagerIds.value,
      startDate: this.searchForm.controls.startDate.value,
      endDate: this.searchForm.controls.endDate.value
    }
    this.dashboardService.subscriptionRisky(JSON.stringify(searchPayload)).subscribe((res: any) => {
      if (res.success) {
        this.riskyCustomer = res?.riskySub
        this.objFromInput['credits'] = { enabled: false }
        this.objFromInput['chart'] = { type: 'pie', plotShadow: false, plotBorderWidth: 0, plotBackgroundColor: '', height: 300, }
        this.objFromInput['title'] = { text: null };
        this.objFromInput['tooltip'] = { pointFormat: '<b>{point.name}</b>: {point.y}', outside: true, };
        this.objFromInput['series'] = [
          {
            data: [
              {
                name: "subcriptions",
                y: this.riskyCustomer.totalSub
              },
              {
                name: "customers",
                y: this.riskyCustomer.totalUsers
              }
            ]
          }
        ]
        this.objFromInput['plotOptions'] = {
          series: {
            dataLabels: [{ enabled: false }],
            cursor: 'pointer',
            showInLegend: true,

          },
          pie: {
            innerSize: '70%'
          }
        }
        this.objFromInput['colors'] = ['#4B49AC', '#f33a3a']
        this.riskyCustomerChartOption = this.objFromInput
        Highcharts.chart('riskyCustomerChart', this.riskyCustomerChartOption)
        this.updateFromInput = true
      }
    })
  }

  liveSubscriptionSummery() {
    const searchPayload = {
      partnerIds: this.searchForm.controls.partnerIds.value,
      accountManagerIds: this.searchForm.controls.accountManagerIds.value,
      startDate: this.searchForm.controls.startDate.value,
      endDate: this.searchForm.controls.endDate.value,
      interval: this.activeSectionForPaid
    }
    const interval = searchPayload.interval

    this.dashboardService.liveSubscriptionsSummary(JSON.stringify(searchPayload)).subscribe((res: any) => {
      if (res.success) {
        const newPaidSubData = res.newPaidSubGraphData.map((obj: any) => {
          return [obj.date, obj.totalSub, obj.totalUsers, obj.totalAmount]
        })

        const churnSubData = res.churnSubGraphData.map((obj: any) => {
          return [obj.date, obj.totalSub, obj.totalUsers, obj.totalAmount]
        })
        this.setChartVariableForPaidSubTrend(newPaidSubData, churnSubData, interval)
      }
    })
  }

  tiralReportData() {
    const searchPayload = {
      partnerIds: this.searchForm.controls.partnerIds.value,
      accountManagerIds: this.searchForm.controls.accountManagerIds.value,
      startDate: this.searchForm.controls.startDate.value,
      endDate: this.searchForm.controls.endDate.value,
      interval: this.activeSection
    }
    const interval = searchPayload.interval
    this.dashboardService.trialReport(JSON.stringify(searchPayload)).subscribe((res: any) => {
      if (res.success) {
        this.tiralData = res?.totalTrialSub
        this.trialEpired = res?.expiredSub
        this.trialPaid = res?.trialToPaidSub
        this.liveTrial = res?.liveTrialSub
        const totalTrialSubData = res.totalTrialSubGraph.map((obj: any) => {
          return [obj.date, obj.totalSub, obj.totalUsers, obj.totalAmount]
        })
        const expiredSubData = res.expiredSubGraph.map((obj: any) => {
          return [obj.date, obj.totalSub, obj.totalUsers, obj.totalAmount]
        })
        const trialToPaidSubData = res.trialToPaidSubGraph.map((obj: any) => {
          return [obj.date, obj.totalSub, obj.totalUsers, obj.totalAmount]
        })
        this.setChartVariableForTrial(totalTrialSubData, expiredSubData, trialToPaidSubData, interval)
      }
    })
  }

  setChartVariableForPaidSubTrend(newPaidSubData: any, churnSubData: any, interval: any) {
    const objFromInput: any = {}
    objFromInput['credits'] = { enabled: false }
    objFromInput['chart'] = { type: this.paidChartType };
    objFromInput['legend'] = { align: 'center', verticalAlign: 'top' ,lineHeight: 0,padding: 0 };
    objFromInput['title'] = { text: '' };
    objFromInput['subtitle'] = { text: `<b>New VS Churn Subscription</b>` };
    objFromInput['yAxis'] = {
      title: {
        text: 'Amount',
      },
    };

    objFromInput['xAxis'] = {
      type: 'datetime',
      title: {
        text: '',
      },
      labels: {
        format: interval === 'month' ? '{value:%b %Y}' : interval === 'year' ? '{value:%Y}' : '{value:%e %b %Y}'
      },
      tickPositioner: function() {
        return this.series[0].xData;
      }
    };

    objFromInput['tooltip'] = {
      formatter: function () {
        const formattedDate = Highcharts.dateFormat('%e %b, %Y', this.x)
        const point = this.point; // Extract the point data
        return `<b>${point.series.name}<b><br/><b>No Of Users :</b> ${point.noOfUsers}<br/><b>No Of Sub : </b>${point.noOfSub}<br/><b>Value : </b>${point.y.toLocaleString('en-IN', { maximumFractionDigits: 0 })} <br/><b>Date : </b>${formattedDate}`;
      },

    };
    objFromInput['plotOptions']= {
      series: {
        borderRadius: 3,
        gapSize: 6
    }
    }
    objFromInput['series'] = [
      {
        name: 'New Paid Subscription',
        data: newPaidSubData.map((data: any) => {
          const x = data[0];
          const noOfSub = data[1].toLocaleString('en-IN', { maximumFractionDigits: 0 })
          const noOfUsers = data[2].toLocaleString('en-IN', { maximumFractionDigits: 0 })
          const y = data[3];
          return {
            x,
            y,
            noOfSub,
            noOfUsers
          };
        }),
      },
      {
        name: 'Churn Subscription',
        data: churnSubData.map((data: any) => {
          const x = data[0];
          const noOfSub = data[1].toLocaleString('en-IN', { maximumFractionDigits: 0 })
          const noOfUsers = data[2].toLocaleString('en-IN', { maximumFractionDigits: 0 })
          const y = data[3];
          return {
            x,
            y,
            noOfSub,
            noOfUsers
          };
        }),
      },
    ];
    this.paidSubTrendOption = objFromInput
    Highcharts.chart('paidSubTrendChart', this.paidSubTrendOption)
    this.updateFromInput = true
  }

  setChartVariableForTrial(totalTrialSubData: any, expiredSubData: any, trialToPaidSubData: any, interval: any) {
    const objFromInput: any = {}
    objFromInput['credits'] = { enabled: false }
    objFromInput['chart'] = { type: this.chartType };
    objFromInput['legend'] = { align: 'center', verticalAlign: 'top', lineHeight: 0,padding: 0 };
    objFromInput['title'] = { text: '' };
    objFromInput['subtitle'] = { text: `<b>Trial Summary Trend<b/>` };
    objFromInput['yAxis'] = {
      title: {
        text: 'Subscriptions',
      },
    };
    objFromInput['colors'] = ['#4B49AC', '#E0E0E0', '#64ABFF']
    objFromInput['xAxis'] = {
      type: 'datetime',
      labels: {
        format: interval === 'month' ? '{value:%b %Y}' : interval === 'year' ? '{value:%Y}' : '{value:%e %b %Y}'
      },
      tickPositioner: function() {
        return this.series[0].xData;
      }
    };
    objFromInput['plotOptions']= {
      series: {
        borderRadius: 3
    }
    },
    objFromInput['tooltip'] = {
      formatter: function () {
        const formattedDate = Highcharts.dateFormat('%e %b, %Y', this.x)
        const point = this.point; // Extract the point data
        return `<b>${point.series.name}</b><br/><b>No Of Users : </b>${point.noOfUsers}<br/><b>No Of Sub : </b>${point.y.toLocaleString('en-IN', { maximumFractionDigits: 0 })}<br/><b>Value : </b>${point.amount} <br/><b>Date : </b>${formattedDate}`;
      }
    };
    objFromInput['series'] = [
      {
        name: 'Trial Subscription',
        data: totalTrialSubData.map((data: any) => {
          const x = data[0];
          const y = data[1];
          const noOfUsers = data[2].toLocaleString('en-IN', { maximumFractionDigits: 0 })
          const amount = data[3].toLocaleString('en-IN', { maximumFractionDigits: 0 })
          return {
            x,
            y,
            amount,
            noOfUsers
          };
        }),
      },
      {
        name: 'Expired Trial',
        data: expiredSubData.map((data: any) => {
          const x = data[0];
          const y = data[1];
          const noOfUsers = data[2].toLocaleString('en-IN', { maximumFractionDigits: 0 })
          const amount = data[3].toLocaleString('en-IN', { maximumFractionDigits: 0 })
          return {
            x,
            y,
            amount,
            noOfUsers
          };
        }),
      },
      {
        name: 'Trial To Paid',
        data: trialToPaidSubData.map((data: any) => {
          const x = data[0];
          const y = data[1];
          const noOfUsers = data[2].toLocaleString('en-IN', { maximumFractionDigits: 0 })
          const amount = data[3].toLocaleString('en-IN', { maximumFractionDigits: 0 })
          return {
            x,
            y,
            amount,
            noOfUsers
          };
        }),
      },
    ];
    this.trialReportChartOption = objFromInput
    Highcharts.chart('trialReportChart', this.trialReportChartOption)
    this.updateFromInput = true
  }

  toggleChartType(graph: string, id: number) {
    if (id == 1) {
      this.chartType = graph
      this.trialReportChartOption['chart'] = { type: graph };
      Highcharts.chart('trialReportChart', this.trialReportChartOption)
      this.updateFromInput = true
    }
    else {
      this.paidChartType = graph
      this.paidSubTrendOption['chart'] = { type: graph };
      Highcharts.chart('paidSubTrendChart', this.paidSubTrendOption)
      this.updateFromInput = true
    }
  }

 
  dateRange(selectedType: string) {
    let startDate: any;
    let endDate: any;
    if (selectedType === 'this_week') {
      startDate = moment().startOf('week').add(1, 'day').toISOString();
      endDate = moment().endOf('week').add(1, 'day').toISOString();
    }
    else if (selectedType === 'last_week') {
      startDate = moment().startOf('week').subtract(6, 'day').toISOString();
      endDate = moment().endOf('week').subtract(6, 'day').toISOString();
    }
    else if (selectedType === 'next_week') {
      startDate = moment().startOf('week').add(8, 'days').toISOString();
      endDate = moment().endOf('week').add(8, 'days').toISOString();
    }
    else if (selectedType === 'this_month') {
      startDate = moment().startOf('month').add('day').toISOString();
      endDate = moment().endOf('month').toISOString();
    }
    else if (selectedType === 'last_month') {
      startDate = moment().startOf('month').subtract(1, 'month').toISOString();
      endDate = moment().endOf('month').subtract(1, 'month').toISOString();
    }
    else if (selectedType === 'next_month') {
      startDate = moment().startOf('month').add(1, 'month').toISOString();
      endDate = moment().endOf('month').add(1, 'month').toISOString();
    }
    else if (selectedType === 'this_year') {
      startDate = moment().startOf('year').toISOString();
      endDate = moment().endOf('year').toISOString();
    }
    else if (selectedType === 'last_year') {
      startDate = moment().startOf('year').subtract(1, 'year').toISOString();
      endDate = moment().endOf('year').subtract(1, 'year').toISOString();
    }
    else if (selectedType === 'next_year') {
      startDate = moment().startOf('year').add(1, 'year').toISOString();
      endDate = moment().endOf('year').add(1, 'year').toISOString();
    }

    this.searchForm.controls.startDate.setValue(startDate);
    this.searchForm.controls.endDate.setValue(endDate);
    this.submitAll()
  }

  toggleDaysMonthYear(section: string, id: number) {
    if (section && id == 1) {
      this.activeSection = section
      this.tiralReportData()
    }
    if (this.activeSectionForPaid && id == 2) {
      this.activeSectionForPaid = section
      this.liveSubscriptionSummery()
    }
  }

  toggleAllSelection(multiSelect: MatSelect, id: number) {
    if (multiSelect && multiSelect.options) {
      multiSelect.options.forEach((item: MatOption) => {
        if (id === 1 && this.allSelected) {
          item.select();

        } else if (id === 2 && this.allPartnerSelected) {
          item.select();

        } else {
          item.deselect();
        }
      });
    }
  }

  optionClick(multiSelect: MatSelect, id: number) {
    let newStatus = true;

    multiSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });

    if (id === 1) {
      this.allSelected = newStatus;
    } else if (id === 2) {
      this.allPartnerSelected = newStatus;
    }
  }

  deselectAll(multiSelect: MatSelect, id: number) {
    if (multiSelect && multiSelect.options) {
      multiSelect.options.forEach((item: MatOption) => {
        if (id === 1) {
          item.deselect();
          this.allSelected = false

        } else if (id === 2) {

          item.deselect();

        }
      });
    }
  }

  get userRole() {
    return this.authService.userRole()
  }
  get searchText() {
    return this.searchForm.controls.searchText.value || '';
  }
  get searchText1() {
    return this.searchForm.controls.searchText1.value || '';
  }
  get userType() {
    return this.authService.userType()
  }
}
