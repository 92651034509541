<div class="main-panel">
    <div class="content-wrapper">
        <div class="row mt-3">
            <div class="text-primary"><b>Server Details</b></div>
            <div class="col-sm-12 grid-margin stretch-card mt-3">
                <div class="card">
                    <div class="card-body">
                        <p class="card-title">Login Trend:</p>
                        <div class="d-flex flex-row justify-content-between">
                            <ul class="days">
                                <li (click)="changeLoginGraphType('line')"
                                    [ngClass]="{'activate': activeLogingraph==='line'}">
                                    <a>Line</a>
                                </li>
                                <li (click)="changeLoginGraphType('column')"
                                    [ngClass]="{'activate': activeLogingraph==='column'}">
                                    <a>Bar</a>
                                </li>
                            </ul>
                            <ul class="days">
                                <li (click)="toggleDaysMonthYearLogin('hour')"
                                    [ngClass]="{'activate': activeLoginSection==='hour'}">
                                    <a>Hour</a>
                                </li>
                                <li (click)="toggleDaysMonthYearLogin('day')"
                                    [ngClass]="{'activate': activeLoginSection==='day'}">
                                    <a>Day</a>
                                </li>
                                <li (click)="toggleDaysMonthYearLogin('week')"
                                    [ngClass]="{'activate': activeLoginSection==='week'}">
                                    <a>Week</a>
                                </li>
                                <li (click)="toggleDaysMonthYearLogin('month')"
                                    [ngClass]="{'activate': activeLoginSection==='month'}">
                                    <a>Month</a>
                                </li>
                                <li (click)="toggleDaysMonthYearLogin('year')"
                                    [ngClass]="{'activate': activeLoginSection==='year'}">
                                    <a>Year</a>
                                </li>
                            </ul>
                        </div>

                        <!-- <p class="card-title graph">85.07% <br /><span class="graph-title">Login Ratio</span> -->
                        <p class=" active my-3 "><svg xmlns="http://www.w3.org/2000/svg" width="8" height="8"
                                viewBox="0 0 8 8" fill="none" class="me-2">
                                <circle cx="4" cy="4" r="4" fill="#2F9E08" />
                            </svg>Active
                        </p>
                        <!-- <p class="heading text-center">Total Number of Logins</p> -->
                        <div id="loginTrendChart"
                            class="chart-container d-flex justify-content-center align-items-center my-3"
                            style="height: 380px;">
                            <highcharts-chart [Highcharts]="loginChart" [options]="loginTrendChartOptions"
                                [(update)]="updateFromInputLogin" style="width: 100%; height: 400px; display: block;">
                            </highcharts-chart>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <p class="card-title fs-5">
                            Server Details:
                            <span class='float-end text-primary pointer' (click)="editServer()">Edit</span>
                        </p>
                        <div class="row">
                            <div class="col-sm-4">
                                <div class="d-flex justify-content-between align-items-center">
                                    <div>
                                        <p class="sub-heading ">Name : </p>
                                        <p class="sub-heading ">Domain : </p>
                                        <p class="sub-heading ">IP :</p>
                                        <p class="sub-heading ">Public Ip:</p>
                                        <p class="sub-heading ">Total C-Drive Space :</p>

                                    </div>
                                    <div>
                                        <p class='details '>{{serverDetail?.name}}</p>
                                        <p class='details '>{{serverDetail?.domainName}}</p>
                                        <p class='details '>{{serverDetail?.ip }}</p>
                                        <p class='details '>{{serverDetail?.publicIp }}</p>
                                        <p class='details '>{{serverDetail?.totalCDriveSpace}} GB</p>
                                    </div>

                                </div>

                            </div>
                            <div class="col-sm-4">
                                <div class="d-flex justify-content-evenly align-items-center">
                                    <div>
                                        <p class="sub-heading ">RAM :</p>
                                        <p class="sub-heading ">CPU : </p>
                                        <p class="sub-heading ">Storage : </p>
                                        <p class="sub-heading ">Available C-Drive space :</p>
                                        <p class="sub-heading "> EXE Version :</p>

                                    </div>
                                    <div>
                                        <p class='details '>{{serverDetail?.ram}} GB</p>
                                        <p class='details '>{{serverDetail?.cpu}} GB</p>
                                        <p class='details '>{{serverDetail?.storage}} GB</p>
                                        <p class='details '> {{serverDetail?.availableCDriveSpace}} GB</p>
                                        <p class='details '> {{serverDetail?.exeBuildVersion}}</p>
                                    </div>
                                </div>

                            </div>
                            <div class="col-sm-4">
                                <div class="d-flex justify-content-evenly align-items-center">
                                    <div>
                                        <p class="sub-heading ">Total Users :</p>
                                        <p class="sub-heading ">Active Users :</p>
                                        <p class="sub-heading ">Inactive Users : </p>
                                        <p class="sub-heading ">Paid Users :</p>
                                        <p class="sub-heading ">Unpaid Users : </p>

                                    </div>
                                    <div>
                                        <p class='details '>{{serverData?.totalInstancesw || 0}}</p>
                                        <p class='details '>{{serverData?.liveInstancesw || 0}}</p>
                                        <p class='details '>{{serverData?.keyw || 0}}</p>
                                        <p class='details '> {{serverData?.totalLiveInstanceso || 0}}</p>
                                        <p class='details '> {{serverData?.trialInstancesp || 0}}</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>

</div>