import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SharedRoutingModule } from './shared-routing.module';
import { SharedComponent } from './shared.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ResellerComponent } from './reseller/reseller.component';
import { BillingComponent } from './billing/billing.component';
import { CreditBackReportComponent } from './credit-back-report/credit-back-report.component';
import { SettingsComponent } from './settings/settings.component';
import { AccountManagerComponent } from './account-manager/account-manager.component';
import { AddResellerComponent } from './reseller/add-reseller/add-reseller.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from '../_helpers/token-interceptor.service';
import { HeaderComponent } from '../header/header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AddAdminComponent } from './account-manager/add-admin/add-admin.component';
import { DeleteModalComponent } from './delete-modal/delete-modal.component';
import { ToastrModule } from 'ngx-toastr';
import { ModalModule, BsModalService } from 'ngx-bootstrap/modal';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { PartnerDetailsComponent } from './reseller/partner-details/partner-details.component';
import { AddBillsModalComponent } from './modals/add-bills-modal/add-bills-modal.component';
import { LogoutComponent } from './modals/logout/logout.component';
import { AddLabelModalComponent } from './modals/add-label-modal/add-label-modal.component';
import { InstanceComponent } from './instance/instance.component';
import { ManageLabelComponent } from './modals/manage-label/manage-label.component';
import { ProductComponent } from './product/product.component';
import { AddProductComponent } from './product/add-product/add-product.component';
import { NewPlanComponent } from './product/add-product/new-plan/new-plan.component';
import { AddAddonComponent } from './product/add-product/add-addon/add-addon.component';
import { NewProductComponent } from './modals/new-product/new-product.component';
import { ChangePlanPriceComponent } from './modals/change-plan-price/change-plan-price.component';
import { ChangeAddonPriceComponent } from './modals/change-addon-price/change-addon-price.component';
import { VirtualMachineComponent } from './virtual-machine/virtual-machine.component';
import { AddVirtualMachineComponent } from './virtual-machine/add-virtual-machine/add-virtual-machine.component';
import { AddServerComponent } from './modals/add-server/add-server.component';
import { ServerComponent } from './server/server.component';
import { InstanceLogComponent } from './instance-log/instance-log.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { RolesPermissionsComponent } from './roles-permissions/roles-permissions.component';
import { AddRolesPermissionsComponent } from './roles-permissions/add-roles-permissions/add-roles-permissions.component';
import { InstanceUserDetailsComponent } from './instance-user-details/instance-user-details.component';
import { UserDetailsComponent } from './user-details/user-details.component';
import { DatePipe } from '@angular/common';
import { AuditLogComponent } from './audit-log/audit-log.component';
import { TallyReleaseComponent } from './tally-release/tally-release.component';
import { AddTallyReleaseComponent } from './modals/add-tally-release/add-tally-release.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { ChangeSubPriceComponent } from './modals/change-sub-price/change-sub-price.component';
import { DiscountComponent } from './discount/discount.component';
import { FilterPipe } from './customPipe/filter.pipe';
import { AddOnComponent } from './modals/add-on/add-on.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { DiscountListComponent } from './discount-list/discount-list.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatButtonModule} from '@angular/material/button';
import { ExtendToExpiryComponent } from './modals/extend-to-expiry/extend-to-expiry.component';
import { SubscriptionDetailsComponent } from './subscription/subscription-details/subscription-details.component';
import { PricePipePipe } from './customPipe/price-pipe.pipe';
import { MrrReportComponent } from './mrr-report/mrr-report.component';
import { HighchartsChartModule } from 'highcharts-angular';
import { PaymentReportComponent } from './payment-report/payment-report.component';
import { ManualPaymentStatusComponent } from './modals/manual-payment-status/manual-payment-status.component';
import { CouponComponent } from './coupon/coupon.component';
import { SchemeDeatilsComponent } from './discount/scheme-deatils/scheme-deatils.component';
import { AccountManagerReportComponent } from './account-manager-report/account-manager-report.component';
import { PartnerReportComponent } from './partner-report/partner-report.component';
import { NewSubscriptionComponent } from './subscription/new-subscription/new-subscription.component';
import { ServerExeComponent } from './modals/server-exe/server-exe.component';
import { PartnerRequestModalComponent } from './modals/partner-request-modal/partner-request-modal.component';
import { OrderManagementComponent } from './order-management/order-management.component';
import { GoogleDriveComponent } from './google-drive/google-drive.component';
import { BannerComponent } from './banner/banner.component';
import { BannerModalComponent } from './modals/banner-modal/banner-modal.component';
import { InactiveSubReportComponent } from './inactive-sub-report/inactive-sub-report.component';
import { TechnicalDashboardComponent } from './technical-dashboard/technical-dashboard.component';
import { ServerDetailsComponent } from './server-details/server-details.component';

@NgModule({
  declarations: [
    SharedComponent,
    DashboardComponent,
    ResellerComponent,
    BillingComponent,
    CreditBackReportComponent,
    SettingsComponent,
    AccountManagerComponent,
    AddResellerComponent,
    HeaderComponent,
    AddAdminComponent,
    DeleteModalComponent,
    PartnerDetailsComponent,
    AddBillsModalComponent,
    LogoutComponent,
    AddLabelModalComponent,
    InstanceComponent,
    ManageLabelComponent,
    ProductComponent,
    AddProductComponent,
    NewPlanComponent,
    AddAddonComponent,
    NewProductComponent,
    ChangePlanPriceComponent,
    ChangeAddonPriceComponent,
    VirtualMachineComponent,
    AddVirtualMachineComponent,
    AddServerComponent,
    ChangeAddonPriceComponent,
    ServerComponent,
    InstanceLogComponent,
    RolesPermissionsComponent,
    AddRolesPermissionsComponent,
    InstanceUserDetailsComponent,
    UserDetailsComponent,
    AuditLogComponent,
    TallyReleaseComponent,
    AddTallyReleaseComponent,
    SubscriptionComponent,
    ChangeSubPriceComponent,
    DiscountComponent,
    FilterPipe,
    AddOnComponent,
    DiscountListComponent,
    ExtendToExpiryComponent,
    SubscriptionDetailsComponent,
    PricePipePipe,
    MrrReportComponent,
    PaymentReportComponent,
    ManualPaymentStatusComponent,
    CouponComponent,
    SchemeDeatilsComponent,
    AccountManagerReportComponent,
    PartnerReportComponent,
    NewSubscriptionComponent,
    ServerExeComponent,
    PartnerRequestModalComponent,
    OrderManagementComponent,
    GoogleDriveComponent,
    BannerComponent,
    BannerModalComponent,
    InactiveSubReportComponent,
    TechnicalDashboardComponent,
    ServerDetailsComponent
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({ timeOut: 5000, }),
    CarouselModule.forRoot(),
    ModalModule,
    MatInputModule,
    MatFormFieldModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatSelectModule,
    MatDividerModule,
    MatCheckboxModule,
    NgxPaginationModule,
    NgbModule,
    HighchartsChartModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptorService,
      multi: true
    },
    DatePipe,
    BsModalService,
    
  ]
})
export class SharedModule { }
