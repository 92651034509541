<!-- ----------------Account--Manager--Page--Start------------------------- -->
<div class="container-scroller">
  <div class="main-panel">
    <div class="content-wrapper">
      <div class="row">
        <div class="col-md-12 mb-3">
          <div class="row">
            <div class="col-sm-6">
              <h3 class="font-weight-bold heading"><b>Cloud Server</b></h3>
            </div>
            <div class="col-sm-6 text-end">
              <select class="custom-select me-3" [(ngModel)]="selectedStatusType" (ngModelChange)="serverListChange()">
                <option value="all">All</option>
                <option value="online">Online</option>
                <option value="offline">Offline</option>
              </select>
              <select class="custom-select me-3" [(ngModel)]="selectedServerType" (ngModelChange)="serverListChange()">
                <!-- <option selected>Select server Type</option> -->
                <option value="trial">Trial Server</option>
                <option value="live">Live Server</option>
                <option value="marg">Marg Server</option>
                <option value="partner">Partner Server</option>
                <option value="custom">Custom Server</option>
              </select>
              <button type="button" class="comman-btn1 btn-primary me-2" (click)="addUpdateServer('')">+
                Server</button>
            </div>
          </div>
        </div>
        <!-- ---------table-section--------- -->
        <div class="row table-section pe-0">
          <div class="col-md-12 grid-margin stretch-card pe-0">
            <div class="card">
              <div class="card-body">
                <div class="d-flex justify-content-between align-items-center mb-3">
                  <div class="  records">{{updatePaginationText}} </div>
                  <div>

                    <div class="dropdown">
                      <button class="comman-btn1 btn-primary dropdown-toggle" type="button" id="dropdownMenuButton"
                        data-mdb-toggle="dropdown" aria-expanded="false">
                        Action
                      </button>
                      <ul class="dropdown-menu menu-item-1 mouseCursor" aria-labelledby="dropdownMenuButton">
                        <li>
                          <a class="dropdown-item" (click)="refreshInstance()">Refresh Server</a>
                        </li>
                        <li>
                          <a class="dropdown-item" (click)="cDriveSpace()">C-drive Space</a>
                        </li>
                        <li>
                          <a class="dropdown-item" (click)="serverExe()">Update Exe</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div *ngIf="!isServerAvailable" class="text-center">
                  <h4>There is no {{selectedStatusType | titlecase}} Server.</h4>

                </div>
                <div class="table-responsive" *ngIf="isServerAvailable">
                  <table class="table table-striped table-borderless table-sm">
                    <thead class="roundedTable thead-header">
                      <tr>
                        <th scope="col" (click)="sortingServer('id')">Name <i class="bi bi-arrow-down-up"></i>
                        </th>
                        <th scope="col">Domain Name</th>
                        <th scope="col">IP Address</th>
                        <th scope="col">Public IP Address</th>
                        <th scope="col">Type</th>
                        <th scope="col" (click)="sortingServer('totalCDriveSpace')">Total Space <i
                            class="bi bi-arrow-down-up"></i></th>
                        <th scope="col" (click)="sortingServer('availableCDriveSpace')">Available Space <i
                            class="bi bi-arrow-down-up"></i></th>
                        <th scope="col" (click)="sortingServer('exeBuildVersion')">Version <i
                            class="bi bi-arrow-down-up"></i></th>
                        <th scope="col" class="text-center" (click)="sortingServer('exeLastUpdated')">Exe Last Updated
                          <i class="bi bi-arrow-down-up"></i>
                        </th>
                        <th scope="col" (click)="sortingServer('isActive')">Status <i class="bi bi-arrow-down-up"></i>
                        </th>
                        <th scope="col">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let server of servers | paginate : config">
                        <td class="productNameCursor" (click)="addUpdateServer(server._id)">
                          <span [ngbTooltip]="server?.description | titlecase" placement="top">
                            {{server?.name|titlecase}}</span>
                        </td>
                        <td>{{server?.domainName}}</td>
                        <td>{{server?.ip}}</td>
                        <td>{{server?.publicIp}}</td>
                        <td [ngClass]="[server.isTrial? 'text-danger': 'text-success']">
                          {{server?.isTrial?'Trial':'Live'}}</td>
                        <td class="text-center">{{server?.totalCDriveSpace}}</td>
                        <td class="text-center">{{server?.availableCDriveSpace}}</td>
                        <td class="text-center">{{server?.exeBuildVersion}}</td>
                        <td>
                          <div class="text-center">{{server?.exeLastUpdated | date :'dd/MM/yyyy'}}</div>
                        </td>
                        <td [ngClass]="[server?.isActive? 'text-success ': 'text-danger']">
                          {{server?.isActive?'Online':'Offline'}}</td>
                        <td class="text-center">
                          <i class="bi bi-arrow-clockwise text-primary productNameCursor me-3 fs-6 "
                            ngbTooltip="Refresh Server" placement="top" (click)="refreshServer(server._id)"></i>
                          <i class="bi bi-pencil-fill text-primary productNameCursor me-3 fs-6 "
                            ngbTooltip="Update Server" placement="top" (click)="addUpdateServer(server._id)"></i>
                          <i *ngIf="server.serverType=='trial'"
                            class="bi bi-bootstrap-reboot text-primary productNameCursor fs-5" ngbTooltip="Reset Server"
                            placement="top" (click)="resetServerModal(server.id)"></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="mt-4 float-end me-4" *ngIf="isServerAvailable">
                  <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- -----end-------- -->
      </div>
    </div>
  </div>