<div class="main-panel">
    <div class="content-wrapper">
        <div class="row">
            <div class="text-primary">Technical Dashboard</div>
            <div class="sticky-container">
                <form [formGroup]="serverForm">
                    <div class="row">
                        <div class="col-md-3">
                            <mat-form-field appearance="fill" class="example-form-field w-100">
                                <mat-label>Server List</mat-label>
                                <mat-select multiple #multiSelect1 formControlName="serverIds">
                                    <div class="custom-panel">
                                        <div class="search ">
                                            <input type="text" class="custom-input-style  p-2"
                                                placeholder="Search here..." formControlName="searchText">
                                        </div>
                                        <div class="select-all">
                                            <mat-checkbox [(ngModel)]="allServerListSelected"
                                                [ngModelOptions]="{standalone: true}"
                                                (change)="toggleAllSelection(multiSelect1,1)">Select All</mat-checkbox>
                                        </div>
                                        <mat-option class="text-truncate"
                                            *ngFor="let list of serverLists | filter: searchText: 'description'"
                                            [value]="list?.id" (click)="optionClick(multiSelect1,1)">
                                            {{list?.description}}</mat-option>
                                    </div>
                                    <footer>
                                        <button mat-raised-button class="me-2"
                                            (click)="submitAll();multiSelect1.close()">Ok</button>
                                        <button mat-button (click)="multiSelect1.close()">Cancel</button>
                                    </footer>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3">
                            <mat-form-field appearance="fill" class="example-form-field w-100">
                                <mat-label>Server Type</mat-label>
                                <mat-select formControlName="serverType">
                                    <div class="custom-panel" (click)="submitAll()">
                                        <mat-option *ngFor="let item of serverType" [value]="item.value">
                                            {{ item.label }}
                                        </mat-option>
                                    </div>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-lg-12">
                <div class="row">
                    <div class="col-md-4 mb-3 stretch-card" *ngFor="let item of serverData">
                        <div class="card border-0 shadow" [ngStyle]="{'background-color' : item.color}"
                            *ngIf="!item.subItems">
                            <div class="card-body">
                                <p class="text-center fs-5 sub mb-1 text-light">{{ item.title }}</p>
                                <p class="text-light text-center fs-5 ">{{item?.total}}</p>
                                <div class="d-flex justify-content-between align-self-center">
                                    <div class="text-center">
                                        <p class="mb-1 text-light">{{item?.free}}</p>
                                        <p class="mb-1 text-light">Free</p>
                                    </div>
                                    <div class="vr text-light"></div>
                                    <div class="text-center">
                                        <p class="mb-1 text-light">{{item?.occupied}}</p>
                                        <p class="mb-1 text-light">Occupied</p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="card border-0 shadow stretch-card" [ngStyle]="{'background-color' : item.color}"
                            *ngIf="item.subItems">
                            <div class="card-body">
                                <p class="text-center fs-5 sub mb-3 text-light">{{ item.title }}</p>
                                <div class="d-flex justify-content-between align-self-center">
                                    <div *ngFor="let subItem of item.subItems">
                                        <div class="d-flex">
                                            <div>
                                                <p class="text-center sub text-light">{{ subItem.title }}</p>
                                                <p class="text-light text-center fs-5 ">{{subItem.count}}</p>
                                            </div>
                                            <div class="vr text-light ms-3" *ngIf="subItem.last"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <p class="sub-heading">Server Summary</p>
                            <div class="table-responsive summary">
                                <table class="table table-striped table-borderless">
                                    <thead class="roundedTable thead-header">
                                        <tr>
                                            <th scope="col">Servers</th>
                                            <th scope="col">RAM</th>
                                            <th scope="col">CPU</th>
                                            <th scope="col">Storage</th>
                                            <th scope="col">Total Users</th>
                                            <th scope="col">Active Users</th>
                                            <th scope="col">Inactive Users</th>
                                            <th scope="col">Paid Users</th>
                                            <th scope="col">Trial Users</th>
                                            <th scope="col">Free Users</th>
                                            <th scope="col">Currently Logged in</th>
                                            <!-- <th scope="col">Highest login - last day</th> -->
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{serverSummary?.noOfServers}}</td>
                                            <td>{{serverSummary?.ram ? serverSummary?.ram + ' GB' : 0}}</td>
                                            <td>{{serverSummary?.cpu ? serverSummary?.cpu + ' Core' : 0}}</td>
                                            <td>{{serverSummary?.storage ? (serverSummary?.storage |number:'1.0-0') +
                                                ' GB' : 0}}
                                            </td>
                                            <td>{{serverSummary?.totalUsers}}</td>
                                            <td>{{serverSummary?.activeUsers}}</td>
                                            <td>{{serverSummary?.inActiveUsers}}</td>
                                            <td>{{serverSummary?.paidUsers}}</td>
                                            <td>{{serverSummary?.trialUsers}}</td>
                                            <td>{{serverSummary?.freeUsers}}</td>
                                            <td>{{serverSummary?.currentlyLoggedInUser}}</td>
                                            <!-- <td>{{summary?.maxLoggedInInterval}}</td> -->
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-md-12">
                    <div class="card">
                        <div class="card-body">
                            <p class="sub-heading">Server Wise Report</p>
                            <div class="table-responsive">
                                <table class="table table-striped table-borderless">
                                    <thead class="roundedTable thead-header">
                                        <tr>
                                            <th scope="col">Server Name</th>
                                            <th scope="col" (click)="sortingServerWiseReport('ram')">RAM <i
                                                    class="bi bi-arrow-down-up"></i></th>
                                            <th scope="col" (click)="sortingServerWiseReport('cpu')">CPU <i
                                                    class="bi bi-arrow-down-up"></i></th>
                                            <th scope="col" (click)="sortingServerWiseReport('storage')">Storage <i
                                                    class="bi bi-arrow-down-up"></i></th>
                                            <th scope="col" (click)="sortingServerWiseReport('totalCDriveSpace')">Total
                                                C Drive Space <i class="bi bi-arrow-down-up"></i></th>
                                            <th scope="col" (click)="sortingServerWiseReport('availableCDriveSpace')">
                                                Available C Drive Space <i class="bi bi-arrow-down-up"></i>
                                            </th>
                                            <th scope="col">Total Users</th>
                                            <th scope="col">Active Users </th>
                                            <th scope="col">Inactive Users </th>
                                            <th scope="col">Paid Users </th>
                                            <th scope="col">Trial Users </th>
                                            <th scope="col">Free Users </th>
                                            <th scope="col">Currently Logged in </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let serverData of serverListData | paginate: config">
                                            <td class="d-inline-block text-truncate" style="width: 150px;"
                                                ngbTooltip="{{serverData?.description | titlecase}}" placement="top">
                                                {{serverData?.description}}</td>
                                            <td>{{serverData?.ram ? serverData?.ram + ' GB' : 0}}</td>
                                            <td>{{serverData?.cpu ? serverData?.cpu + ' Core' : 0}}</td>
                                            <td>{{serverData?.storage ? serverData?.storage +
                                                ' GB' : 0}}
                                            </td>
                                            <td>{{serverData?.totalCDriveSpace ? serverData?.totalCDriveSpace +
                                                ' GB' : 0}}
                                            </td>
                                            <td>{{serverData?.availableCDriveSpace ?
                                                serverData?.availableCDriveSpace + ' GB' :
                                                0}}</td>
                                            <td>{{serverData?.totalUsers}}</td>
                                            <td>{{serverData?.activeUsers}}</td>
                                            <td>{{serverData?.inActiveUsers}}</td>
                                            <td>{{serverData?.paidUsers}}</td>
                                            <td>{{serverData?.trialUsers}}</td>
                                            <td>{{serverData?.freeUsers}}</td>
                                            <td>{{serverData?.currentlyLoggedInUser}}</td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>
                            <div class="mt-4 float-end me-4">
                                <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>