import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as Highcharts from 'highcharts';
import { ToastrService } from 'ngx-toastr';
import { TechnicalDashboardService } from 'src/app/services/technical-dashboard.service';
import { AddServerComponent } from '../modals/add-server/add-server.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-server-details',
  templateUrl: './server-details.component.html',
  styleUrls: ['./server-details.component.scss']
})
export class ServerDetailsComponent implements OnInit {

  cloudId: any;
  activeSection = "day"
  activeGraph = "line"
  activeLogingraph = "line"
  activeLoginSection = 'day'
  Highcharts: typeof Highcharts = Highcharts;
  loginChart: typeof Highcharts = Highcharts
  loginChartOptions: any = {}
  loginTrendChartOptions: Highcharts.Options = {}
  updateFromInputLogin = true
  loginTrendGraphData: any = []
  serverData: any
  serverDetail: any
  bsModalRef!: BsModalRef


  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private technicalDashboardService: TechnicalDashboardService,
    private modalService: BsModalService
  ) { }

  ngOnInit(): void {
    this.cloudId = this.route.snapshot.queryParamMap.get('cloudId') || '';
    this.cloudServerReport()
    this.loginTrendGraph(this.activeLoginSection)
  }

  toggleDaysMonthYearLogin(type: any) {
    this.activeLoginSection = type
    this.loginTrendGraph(this.activeLoginSection)
  }

  changeLoginGraphType(graphType: any) {
    this.activeLogingraph = graphType
    this.loginChartOptions['chart'] = {
      type: this.activeLogingraph
    }

    this.loginTrendChartOptions = this.loginChartOptions
    Highcharts.chart('loginTrendChart', this.loginTrendChartOptions)
    this.updateFromInputLogin = true
  }

  loginTrendGraph(activeLoginSection: any) {
    this.loginChartOptions['chart'] = {
      type: this.activeLogingraph
    }
    this.loginChartOptions['title'] = {
      text: 'Number of Logins'
    }
    this.loginChartOptions['credits'] = {
      enabled: false
    }
    this.loginChartOptions['yAxis'] = {
      title: {
        text: 'Count'
      }
    }

    this.technicalDashboardService.getLoginTrendGraphData(this.cloudId, activeLoginSection).subscribe((data: any) => {
      this.loginTrendGraphData = data
      this.loginChartOptions['series'] = data.series
      this.loginChartOptions['xAxis'] = {
        categories: data.categories, title: {
          text: "Date"
        }
      }
      this.loginChartOptions['tooltip'] = {
        formatter: function () {
          return `
            <div style="text-align: left;">
              <b>Date:</b> ${this.x} <br />
              <b>No. of Logins:</b> ${this.y} <br />
            </div>
          `;
        }
      };
      this.loginTrendChartOptions = this.loginChartOptions
      Highcharts.chart('loginTrendChart', this.loginTrendChartOptions)
      this.updateFromInputLogin = true
    })
  }

  cloudServerReport() {
    this.technicalDashboardService.getServerDetail(this.cloudId).subscribe((res: any) => {
      if (res.success) {
        this.serverData = res?.instanceStats[0];
        this.serverDetail = res?.serverDetail;
      } else {
        this.toastr.error('Server details not found.')
      }
    }, (error) => {
      this.toastr.error(error.error.message)
    })
  }

  editServer() {
    this.bsModalRef = this.modalService.show(AddServerComponent, {
      class: 'modal-lg',
      backdrop: 'static'
    })
    this.bsModalRef.content.serverId = this.cloudId
    this.bsModalRef.content.event.subscribe((res: any) => {
      if (res === 'ok') {
        this.cloudServerReport();
      }
      else {
        this.cloudServerReport();
      }
    })
  }

}
