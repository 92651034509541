import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrService } from 'ngx-toastr';
import { ServerService } from 'src/app/services/server.service';

@Component({
  selector: 'app-add-server',
  templateUrl: './add-server.component.html',
  styleUrls: ['./add-server.component.scss']
})
export class AddServerComponent implements OnInit {

  event: EventEmitter<any> = new EventEmitter();
  @Input() serverId: string;
  isEdit: boolean = false

  serverForm = new FormGroup({
    name: new FormControl('', [Validators.pattern('[a-zA-Z]+.*|.*[a-zA-Z]+|.*[a-zA-Z]+.*'), Validators.required]),
    description: new FormControl(''),
    ip: new FormControl('', [Validators.pattern('^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'), Validators.required]),
    publicIp: new FormControl('', [Validators.pattern('^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$'), Validators.required]),
    ram: new FormControl('48', [Validators.required]),
    cpu: new FormControl('8', [Validators.required]),
    storage: new FormControl('250', [Validators.required]),
    domainName: new FormControl('', Validators.required),
    serverType: new FormControl('live', Validators.required),
    selectExe: new FormControl('newExe'),
    autoProvision: new FormControl('yes'),
    userName: new FormControl('',Validators.required),
    password: new FormControl('',[Validators.required,Validators.minLength(6)]),
  })

  constructor(
    private toasts: ToastrService,
    public bsModalRef: BsModalRef,
    private serverService: ServerService
  ) { }

  ngOnInit(): void {
    setTimeout(() => {
      if (this.serverId) {
        this.isEdit = true
        this.serverDetails()
      }
    });
  }

  closedModal() {
    this.bsModalRef.hide();
  }

  submit() {
    if (this.serverForm.valid) {
      if (this.isEdit) {
        this.updateServer()
      }
      else {
        this.createServer();
      }
    } else {
      this.serverForm.markAllAsTouched()
    }

  }

  createServer() {
    const serverPayload = this.serverForm.value
    this.serverService.createServer(serverPayload).subscribe((res: any) => {
      if (res.success) {
        this.closedModal();
        this.toasts.success(res.message);
        this.event.emit('ok')
        this.serverForm.reset();
      }
    },
      (error) => {
        this.toasts.error(error.error.message)
      })
  }

  serverDetails() {
    this.serverService.serverDetail(this.serverId).subscribe((res: any) => {
      if (res.success) {
        const server = res.server
        server.selectExe = server.isOldInfra ? 'oldExe' : 'newExe';
        this.serverForm.patchValue(res.server);
      }
    })
  }

  updateServer() {
    let serverPayload = this.serverForm.value
    serverPayload = Object.assign(serverPayload, { serverId: this.serverId })
    this.serverService.editServer(serverPayload).subscribe((res: any) => {
      if (res.success) {
        this.closedModal();
        this.toasts.success(res.message);
        this.event.emit('ok')
      }
    }, (error) => {
      this.toasts.error(error.error.message)
    })
  }

}
