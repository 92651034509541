<div class="main-panel">
  <div class="content-wrapper">
    <div class="row">
      <div class="col-md-12 d-flex justify-content-between align-items-center">
        <div class="col-md-9">
          <h3 class="font-weight-bold heading"><b>Infra Api Logs</b></h3>
        </div>
        <div class="col-md-3">
          <form [formGroup]="searchForm">
            <mat-form-field appearance="fill" class="example-form-field w-100">
              <mat-label>Custom Date</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input matStartDate placeholder="From" formControlName="startDate">
                <input matEndDate placeholder="To" formControlName="endDate">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker>
                <mat-date-range-picker-actions>
                  <button mat-button matDateRangePickerCancel>Cancel</button>
                  <button mat-raised-button color="primary" matDateRangePickerApply
                    (click)="virtualMachineLogReport()">Apply</button>
                </mat-date-range-picker-actions>
              </mat-date-range-picker>
            </mat-form-field>

          </form>
        </div>
      </div>

      <div class="col-md-12 mt-4">
        <div class="card">
          <div class="card-body">
            <h4 class="font-weight-bold sub-heading">Infra Api Log Report <Button *ngIf="endPoints.length>0"
                class="exl-btn float-end ms-2 excel mb-4" (click)="exportReportInExcel()">Export To
                Excel</Button>
            </h4>
            <p class="text-center fs-5 mt-4" *ngIf="endPoints.length==0">No Data found !</p>
            <div class="col-12 table-responsive  ps-0 pe-0" *ngIf="endPoints.length>0">
              <table class="table table-bordered fixed-columns-table" #tableData>
                <thead class="thead-header">
                  <tr>
                    <th scope="col ">&nbsp;</th>
                    <th scope="col ">&nbsp;</th>
                    <th colspan="2" scope="col" *ngFor="let date of dates">
                      {{date}}
                    </th>
                  </tr>
                  <tr>
                    <th scope="col ">S No.</th>
                    <th scope="col ">Message</th>
                    <ng-container *ngFor="let date of dates">
                      <th scope="col">Success</th>
                      <th scope="col">Failed</th>
                    </ng-container>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let endPoint of endPoints; let i = index">
                    <td>{{i + 1}}</td>
                    <td class="d-inline-block text-truncate" ngbTooltip="{{endPoint | titlecase}}" placement="top">
                      {{endPoint}}
                    </td>
                    <ng-container *ngFor="let date of dates">
                      <td (click)="showDetails(endPoint,date,'success')"
                        [ngClass]="showData(endPoint,date,'success') > 0 ? 'table-pointer' : ''">
                        {{showData(endPoint,date,'success')}}
                      </td>
                      <td (click)="showDetails(endPoint,date,'failed')"
                        [ngClass]="showData(endPoint,date,'failed') > 0 ? 'table-pointer' : ''">
                        {{showData(endPoint,date,'failed')}}
                      </td>
                    </ng-container>
                  </tr>
                </tbody>
              </table>

            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12 mt-4 " *ngIf="isShowDetails">
        <div class="sub-heading mb-3 ms-2 me-2">Infra Api Log List <span class="float-end ms-2 excel"
            (click)="excelInfraApiLogData()" *ngIf="vmMachineLog.length>0">Export To Excel</span>

        </div>
        <div class="card">
          <div class="card-body">
            <div>
              <span class="records ">{{pagination}}</span>
            </div>
            <div class="table-responsive mt-2" *ngIf="vmMachineLog.length>0">
              <table class="table table-bordered detail-table" id="google-drive-log">
                <thead class="thead-header">
                  <tr>
                    <th scope="col">S.No</th>
                    <th scope="col">Date & Time</th>
                    <th scope="col">Name</th>
                    <th scope="col">Email</th>
                    <th scope="col">UserType</th>
                    <th scope="col" class="textWrap">API Request Payload</th>
                    <th scope="col">API Request URL</th>
                    <th scope="col">API Response</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let log of vmMachineLog | paginate:config let i=index;">
                    <td>
                      {{i+1}}
                    </td>
                    <td>{{log?.createdAt|date:'dd MMM YY HH:mm'}}</td>
                    <td>{{ (log?.customer?.name || log?.partner?.name) | titlecase }}</td>
                    <td>{{ (log?.customer?.email || log?.partner?.email) | titlecase }}</td>
                    <td>{{log?.createdBy |titlecase}}</td>
                    <td ngbTooltip="{{log?.apiRequestPayload}}" placement="top" class="d-inline-block">
                      <div class="d-inline-block text-truncate " style="max-width: 200px;">
                        {{log?.apiRequestPayload}}
                      </div>
                    </td>
                    <td >
                      <span class="d-inline-block text-truncate " ngbTooltip="{{log?.apiRequestUrl}}" placement="top" style="max-width: 200px;">
                        {{log?.apiRequestUrl}}
                      </span>
                    </td>
                    <td class="text-truncate " ngbTooltip="{{log?.apiResponse}}" placement="top">
                      {{log?.apiResponse}}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="mt-4 float-end me-4" *ngIf="vmMachineLog.length>0">
              <pagination-controls (pageChange)="pageChanged($event)"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>