import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { error } from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { ServerService } from 'src/app/services/server.service';
import { TechnicalDashboardService } from 'src/app/services/technical-dashboard.service';

@Component({
  selector: 'app-technical-dashboard',
  templateUrl: './technical-dashboard.component.html',
  styleUrls: ['./technical-dashboard.component.scss']
})
export class TechnicalDashboardComponent implements OnInit {

  @ViewChild('multiSelect1') multiSelect1!: MatSelect;

  sortSelected: boolean = false
  allServerListSelected = false
  isServerDataAvailable: boolean = false
  serverLists!: []
  cloud: any
  serverListData: any
  serverSummary: any = {}
  lastSortedField: string = 'id';
  sortDirection: number = -1;
  serverType = [
    { value: 'trial', label: 'Trial Server' },
    { value: 'live', label: 'Live Server' },
    { value: 'marg', label: 'Marg Server' },
    { value: 'partner', label: 'Partner Server' },
    { value: 'custom', label: 'Custom Server' },
  ]
  serverData = [
    { title: 'Total Instance', total: 0, free: 0, occupied: 0, color: '#4B49AC' },
    { title: 'Live Instance', total: 0, free: 0, occupied: 0, color: '#00ab41' },
    { title: 'Trial Instance', total: 0, free: 0, occupied: 0, color: '#FA7070' },
    { title: 'Marg Instance', total: 0, free: 0, occupied: 0, color: '#03AED2' },
    { title: 'Partner Instance', total: 0, free: 0, occupied: 0, color: '#4389F9' },
    {
      title: 'Offline Servers ', color: '#FFAD60', subItems: [
        { title: 'Live', count: 0, last: true },
        { title: 'Trial', count: 0, last: true },
        { title: 'Partner', count: 0, last: true },
        { title: 'Marg', count: 0, last: false },
      ]
    }]
  totalCount: number = 0
  page: number = 1
  serverForm = new FormGroup({
    serverName: new FormControl(''),
    serverType: new FormControl(''),
    searchText: new FormControl(''),
    filterUpdateExe: new FormControl<boolean>(false),
    serverIds: new FormControl('')
  })

  config = {
    itemsPerPage: 25,
    currentPage: this.page,
    totalItems: this.totalCount
  };

  constructor(
    private technicalDashboardService: TechnicalDashboardService,
    private toastr: ToastrService,
    private serverService: ServerService
  ) { }

  ngOnInit(): void {
    this.serverStats()
    this.allServerList()
    this.serverSummaryData()
    this.serverWiseReportData()

  }

  serverStats() {
    this.technicalDashboardService.getInstanceReport().subscribe((res: any) => {
      if (res.success) {
        const statsData = res?.statsData
        this.serverData = [
          { title: 'Total Instance', total: statsData?.totalInstance, free: statsData?.freeInstance, occupied: statsData?.occupiedInstance, color: '#4B49AC' },
          { title: 'Live Instance', total: statsData?.totalLiveInstance, free: statsData?.freeLiveInstance, occupied: statsData?.occupiedLiveInstance, color: '#00ab41' },
          { title: 'Trial Instance', total: statsData?.totalTrialInstance, free: statsData?.freeTrialInstance, occupied: statsData?.occupiedTrialInstance, color: '#FA7070' },
          { title: 'Marg Instance', total: statsData?.totalMargInstance, free: statsData?.freeMargInstance, occupied: statsData?.occupiedMargInstance, color: '#03AED2' },
          { title: 'Partner Instance', total: statsData?.totalPartnerInstance, free: statsData?.freePartnerInstance, occupied: statsData?.occupiedPartnerInstance, color: '#4389F9' },
          {
            title: 'Offline Servers ', color: '#FFAD60', subItems: [
              { title: 'Live', count: statsData?.liveServer, last: true },
              { title: 'Trial', count: statsData?.trialServer, last: true },
              { title: 'Partner', count: statsData?.partnerServer, last: true },
              { title: 'Marg', count: statsData?.margServer, last: false },
            ]
          }]
      }
    }, (error) => {
      this.toastr.error(error.error.message)
    })
  }

  serverWiseReportData() {
    const searchPayload = {
      serverType: this.serverForm.controls.serverType.value,
      serverIds: this.serverForm.controls.serverIds.value,
      sorting: { [this.lastSortedField]: this.sortDirection }
    }

    this.technicalDashboardService.serverDetailsList(this.config.currentPage, this.config.itemsPerPage, JSON.stringify(searchPayload)).subscribe((res: any) => {
      if (res.success) {
        this.serverListData = res?.serverReport
        this.isServerDataAvailable = true
        this.config.currentPage = res?.totalServers?.pages
        this.config.totalItems = res?.totalServers?.total
      }
    }, (error) => {
      this.isServerDataAvailable = false
      this.config.currentPage = 1
      this.config.totalItems = 0
      this.toastr.error(error.error.message)
    })
  }

  sortingServerWiseReport(field: string) {
    if (this.lastSortedField !== field) {
      this.sortDirection = -1;
    } else {
      this.sortDirection = this.sortDirection === -1 ? 1 : -1;
    }
    this.lastSortedField = field;
    this.serverWiseReportData()
  }

  serverSummaryData() {
    const searchPayload = {
      serverType: this.serverForm.controls.serverType.value,
      serverIds: this.serverForm.controls.serverIds.value
    }
    this.technicalDashboardService.serverSummaryReport(JSON.stringify(searchPayload)).subscribe((res: any) => {
      if (res.success) {
        this.serverSummary = res?.summaryData
      }
    }, (error) => {
      this.toastr.error(error.error.message)
    })
  }


  submitAll() {
    this.serverSummaryData()
    this.serverWiseReportData()
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
    this.serverWiseReportData()
  }

  toggleAllSelection(multiSelect: MatSelect, id: number) {
    if (multiSelect && multiSelect.options) {
      multiSelect.options.forEach((item: MatOption) => {
        if (id === 1 && this.allServerListSelected) {
          item.select();
        } else {
          item.deselect();
        }
      });
    }
  }

  optionClick(multiSelect: MatSelect, id: number) {
    let newStatus = true;
    multiSelect.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });

    if (id === 1) {
      this.allServerListSelected = newStatus;
    }
  }

  deselectAll(multiSelect: MatSelect, id: number) {
    if (multiSelect && multiSelect.options) {
      multiSelect.options.forEach((item: MatOption) => {
        if (id === 1) {
          item.deselect();
          this.allServerListSelected = false
        }
      });
    }
  }

  allServerList() {
    const filterOutUpdateExe = this.serverForm.controls.filterUpdateExe.value
    this.serverService.allServerList(filterOutUpdateExe).subscribe((res: any) => {
      if (res.success) {
        this.serverLists = res?.servers
      }
    }, (err) => {
      this.toastr.error(err.error.message)
      this.serverLists = []
    })
  }

  get searchText() {
    return this.serverForm.controls.searchText.value || ''
  }
}
