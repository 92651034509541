import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/services/auth.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { InstanceService } from 'src/app/services/instance.service';

@Component({
  selector: 'app-virtual-machine',
  templateUrl: './virtual-machine.component.html',
  styleUrls: ['./virtual-machine.component.scss']
})
export class VirtualMachineComponent implements OnInit {
  @ViewChild('tableData', { static: false }) tableData: ElementRef;
  isShowDetails: boolean = false
  searchText: string = ''
  vmMachineLog: any[] = []
  totalCount: number = 0
  page: number = 1
  successLogs: any = []
  failedLogs: any = []
  endPoints: any = []
  dates: any = []
  selectedDate: string = ''
  selectedEndPoint: string = ''
  selectedStatus: string = ''

  config = {
    itemsPerPage: 30,
    currentPage: this.page,
    totalItems: this.totalCount
  };

  searchForm = new FormGroup({
    startDate: new FormControl(''),
    endDate: new FormControl(''),
  })
  constructor(
    private authService: AuthService,
    private instanceService: InstanceService,
    private toastrService: ToastrService
  ) { }

  ngOnInit(): void {
    const startDate = moment().startOf('month').toISOString();
    const endDate = moment().endOf('month').toISOString();
    this.searchForm.controls.startDate.setValue(startDate);
    this.searchForm.controls.endDate.setValue(endDate)
    this.virtualMachineLogReport()
  }

  get pagination() {
    const paginationData = this.authService.getPaginationText(this.config.currentPage, this.config.itemsPerPage, this.config.totalItems)
    return paginationData
  }

  virtualMachineLogReport() {
    const payload = {
      startDate: this.searchForm.controls.startDate.value,
      endDate: this.searchForm.controls.endDate.value
    }

    this.instanceService.virtualMachineLogReport(JSON.stringify(payload)).subscribe((res: any) => {
      if (res.success) {
        this.successLogs = res.successLogs
        this.failedLogs = res.failedLogs
        this.endPoints = res.endPoints
        this.dates = res.dates
      }
    }, (err) => {
      this.toastrService.error(err.error.message)
    })
  }

  showData(endPoint: string, date: string, status: string): number {
    let count = 0
    const logs = (status === 'success') ? this.successLogs : this.failedLogs
    logs.forEach((obj: any) => {
      if (obj.endPoint === endPoint && obj.date === date) {
        count = obj.count
      }
    });
    return count
  }

  pageChanged(event: any) {
    this.config.currentPage = event;
    this.showDetails(this.selectedEndPoint, this.selectedDate, this.selectedStatus)
  }

  excelInfraApiLogData() {
    const serachPayload = {
      date: this.selectedDate,
      endPoint: this.selectedEndPoint,
      status: this.selectedStatus
    }
    this.instanceService.virtualMachineLogExcel(JSON.stringify(serachPayload)).subscribe((res: any) => {
      if (res.success) {
        this.exportexcel(res.vmExcelData)
      }
    })
  }

  exportexcel(schedulerLogs: any): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(schedulerLogs);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'InfraApiDetails.xlsx');
  }

  exportReportInExcel(): void {
    const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(this.tableData.nativeElement);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, 'InfraApiLogReport.xlsx');
  }

  showDetails(endPoint: string, date: string, status: string) {
    this.selectedDate = date;
    this.selectedEndPoint = endPoint;
    this.selectedStatus = status;
    const serachPayload = {
      date: date,
      endPoint: endPoint,
      status: status
    }
    this.instanceService.virtualMachineLogList(this.config.currentPage, this.config.itemsPerPage, JSON.stringify(serachPayload)).subscribe((res: any) => {
      if (res.success) {
        this.isShowDetails = true
        this.vmMachineLog = res.infraApiLists;
        this.config.currentPage = res?.total?.pages || 1;
        this.config.totalItems = res?.total?.total || 0;
      }
    }, (err) => {
      this.isShowDetails = false
      this.vmMachineLog = []
      this.config.currentPage = 1;
      this.config.totalItems = 0;
      this.toastrService.error(err.error.message)
    })
  }
}
